/* eslint-disable no-param-reassign */

import getters from './getters/fueler';

export default {
  state: {
    fuelerEntries: [],
    fuelerEntriesOnline: [],
    fuelerLocation: [],
    fuelerEquipment: [],
  },
  mutations: {
    setSavedFuelerRecords(state, entries) {
      state.fuelerEntries = entries;
    },
    setOnlineFuelerRecords(state, entries) {
      state.fuelerEntriesOnline = entries;
    },
    setfuelerLocation(state, entries) {
      state.fuelerLocation = entries;
    },
    setfuelerEquipment(state, entries) {
      state.fuelerEquipment = entries;
    },
  },
  getters,
};
