<template>
  <v-card>
    <v-card-title class="justify-center">
      <h3>Add Injury To Week</h3>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-textarea
          id="injury-form-input"
          :value="injury.injury_comment"
          outlined
          label="Describe Event"
          counter="80"
          @input="handleDescription"
        />
        <v-checkbox
          label="Supervisor Notified"
          :input-value="injury.notified_supervisor"
          true-value="1"
          false-value="0"
          @change="handleNotified"
        />
      </v-container>
    </v-card-text>
    <v-card-actions
      class="d-flex justify-end"
    >
      <v-btn
        id="cancelSave"
        color="warning"
        @click="$emit('closeInjury')"
      >
        Close
      </v-btn>
      <v-btn
        color="success"
        :loading="saving"
        :disabled="$v.$invalid"
        @click="saveInjury"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  required,
  maxLength,
} from 'vuelidate/lib/validators';

export default {
  name: 'InjuryEntry',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    injury: {
      type: Object,
    },
  },
  data() {
    return {
      saving: false,
      editing: false,
      newInjuredObj: {
        notified: false,
        injury_comment: null,
        injury_date: '',
        notified_supervisor: '0',
      },
    };
  },
  created() {
    this.newInjuredObj = { ...this.injury };
    this.$v.$touch();
  },
  validations: {
    newInjuredObj: {
      injury_comment: {
        required,
        maxLength: maxLength(80),
      },
    },
  },
  methods: {
    async saveInjury() {
      const notified = this.newInjuredObj.notified ? this.newInjuredObj.notified : this.injury.notified_supervisor;
      const userData = {
        date_start: this.$store.getters.dates[0].date,
        date_end: this.$store.getters.dates[6].date,
        notified_supervisor: notified,
        injury_comment: this.newInjuredObj.injury_comment,
        emp_num: this.$store.getters.user.emp_num,
        id: this.injury.id,
      };
      this.saving = true;
      const response = await this.$store.dispatch('saveInjury', userData);
      this.saving = false;
      if (!response.error) {
        this.$emit('closeInjury');
      }
    },
    handleDescription(event) {
      this.editing = true;
      this.newInjuredObj.injury_comment = event;
    },
    handleNotified(event) {
      this.editing = true;
      this.newInjuredObj.notified = event;
    },
  },
};
</script>
