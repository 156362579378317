/*
Requires the file name,
Base64 encoded string of data,
and the type of file that is
being downloaded,
(ex: application/pdf)
*/

/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
export default {
  methods: {
    download(filename, data, mimeType) {
      const blob = this.b64toBlob(data, mimeType);
      if (cordova && cordova.platformId !== 'browser') {
        let storageLocation = '';
        switch (window.device.platform) {
          case 'Android':
            storageLocation = cordova.file.externalDataDirectory;
            break;

          case 'iOS':
            storageLocation = cordova.file.documentsDirectory;
            break;
          default:
            alert('failure');
            break;
        }

        const folderPath = storageLocation;

        window.resolveLocalFileSystemURL(
          folderPath,
          (dir) => {
            dir.getFile(
              filename,
              {
                create: true,
              },
              (file) => {
                file.createWriter(
                  (fileWriter) => {
                    fileWriter.write(blob);

                    fileWriter.onwriteend = () => {
                      const url = file.toURL();
                      cordova.plugins.fileOpener2.open(url, mimeType, {
                        error: function error() {
                          alert('Unable to download');
                        },
                        success: function success() {
                        },
                      });
                    };

                    fileWriter.onerror = () => {
                      alert('Unable to download');
                    };
                  },
                  () => {
                    // failed
                    alert('Unable to download');
                  },
                );
              },
              () => {
                alert('Unable to download');
              },
            );
          },
          () => {
            alert('Unable to download');
          },
        );
      }
    },
    b64toBlob(b64Data, contentType, sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: contentType });
    },
  },
};
