<template>
  <HomeAppDefault :native-apps="nativeApps" />
</template>

<script>
import HomeAppDefault from './components/HomeApp.vue';

export default {
  name: 'HomePg',
  components: {
    HomeAppDefault,
  },
  data() {
    return {
      nativeApps: [],
    };
  },
  async created() {
    //Must be online at least one time to use this app
    this.nativeApps = await this.$store
      .dispatch('setImportedTemplates')
      .then(() => {
        //remove native apps from imported templates
        return this.$store.getters.importedTemplates.filter((e) => {
          return e.center === 'app'
            && e.section === 'app'
            && e.tab === 'app';
        });
      });
  },
};
</script>
