export default {
  latestVersion(state) {
    return state.latest_version;
  },
  currentVersion(state) {
    return state.current_version;
  },
  server(state) {
    return state.server;
  },
  online(state) {
    return state.online;
  },
  user(state) {
    return state.user;
  },
  error(state) {
    return state.error;
  },
  loading(state) {
    return state.loading;
  },
  snackbar(state) {
    return state.snackbar;
  },
  defaultsDialog(state) {
    return state.defaultsDialog;
  },
  dates(state, getters) {
    return state.dates[getters.week] || [];
  },
  lastWeekDates(state) {
    return state.dates.last || [];
  },
  bothWeekDates(state) {
    return state.dates || [];
  },
  employees(state) {
    return state.employees || [];
  },
  equipment(state) {
    return state.equipment || [];
  },
  jobs(state) {
    return state.jobs || [];
  },
  presentWeekDates(state) {
    return state.dates.present || [];
  },
};
