<template>
  <!-- DYNAMIC SNACKBAR -->
  <v-snackbar
    id="dynamic-snackbar-alert"
    v-model="$store.getters.snackbar.show"
    :timeout="$store.getters.snackbar.time || -1"
    :color="$store.getters.snackbar.color"
  >
    <v-flex>
      <v-layout
        justify-space-between=""
        align-center
      >
        {{ $store.getters.snackbar.message }}
        <v-btn
          icon
          @click="$store.getters.snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-layout>
    </v-flex>
  </v-snackbar>
</template>

<script>
/**
 * @descript SnackBar | a universal method for exposing a snackbar to a user
 */
export default {
  name: 'SnackBar',
};
</script>
