import fetchData from '../../../utils/fetchData';
import fetchDataNewAPI from '../../../mixins/fetchDataNewAPI';

export default {
  async fetchDataNew(apicstk, userData, server) {
    //If you dont send userdata, it will fix itself
    if (!server) {
      const newServer = userData;
      return fetchDataNewAPI.methods.fetchDataNew(apicstk, {}, { alternate_url: `${newServer}controller.php` });
    }
    return fetchDataNewAPI.methods.fetchDataNew(apicstk, userData, { alternate_url: `${server}controller.php` });
  },
  getJobs(server) {
    const allJobsCstk = {
      center: 'pm',
      section: 'return',
      tab: 'all',
      kind: 'jobsAllowedInTimecards',
    };
    return this.fetchDataNew(allJobsCstk, server);
  },
  getEmployees(server) {
    const allEmpCstk = {
      center: 'employee',
      section: 'return',
      tab: 'all',
      kind: 'currentEmployees',
    };
    return this.fetchDataNew(allEmpCstk, server);
  },
  getEquipment(server) {
    const allEquipmentCstk = {
      center: 'equipment',
      section: 'return',
      tab: 'all',
      kind: 'timecardEquipment',
    };
    return this.fetchDataNew(allEquipmentCstk, server);
  },
  getJobEquipment(server) {
    const jobEquipmentCstk = {
      center: 'equipment',
      section: 'return',
      tab: 'all',
      kind: 'equipmentWithJobnums',
    };
    return fetchData(jobEquipmentCstk, null, {}, server);
  },
  getUser(server) {
    const userCstk = ['user', 'return', 'single', 'loggedInUserInfo'];
    return this.fetchDataNew(userCstk, server);
  },
  getCostcodes(server) {
    const allCostCodesCstk = {
      center: 'pm',
      section: 'return',
      tab: 'all',
      kind: 'costCodesWithDescription',
    };
    return this.fetchDataNew(allCostCodesCstk, server);
  },
  getRepairCostcodes(server) {
    const allRepairCodesCstk = {
      center: 'pm',
      section: 'return',
      tab: 'all',
      kind: 'equipmentRepairCostCodes',
    };
    return this.fetchDataNew(allRepairCodesCstk, server);
  },
};
