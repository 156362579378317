<template>
  <v-dialog
    v-model="$store.getters.updateApp.display"
    persistent
    max-width="90%"
  >
    <v-card
      id="userAlertTitle"
      class="mx-auto"
      outlined
    >
      <v-card-title class="align-items-center d-flex justify-space-between">
        Update App
        <img
          src="img/logo-124x80.png"
          alt="Rotschy Inc"
          itemprop="logo"
          width="62"
          height="40"
        >
      </v-card-title>
      <v-divider />
      <v-card-text
        id="userAlertMessage"
        class="pa-5"
      >
        <object
          type="text/html"
          :data="`${$store.getters.server }controller.php?${ templatename}`"
          width="100%;"
          style="min-height: 59vh;"
        >
        </object>
      </v-card-text>
      <v-divider />
      <v-container class="d-flex justify-end">
        <v-btn
          id="userAlertClose"
          @click="updateLater()"
        >
          Close
        </v-btn>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'UpdateApp',
  data() {
    return {
      templatename: '',
      url: {
        center: 'employee',
        section: 'time card',
        tab: 'enter',
        kind: 'app-download-info',
      },
    };
  },
  created() {
    this.$store.dispatch('getLatestAppVersion');
    const {
      center, section, tab, kind,
    } = this.url;
    this.templatename = encodeURI(`center=${center}&section=${section}&tab=${tab}&kind=${kind}&dataType=html`);
  },
  methods: {
    updateLater() {
      this.$store.dispatch('setUpdateApp', {
        display: false, later: true, update: true, loading: false,
      });
    },
  },
};
</script>
