<template>
  <v-card>
    <v-card>
      <v-card-title>
        Cash Out PTO
      </v-card-title>
      <v-card-text class="py-0">
        *This will add a Pto record to sunday, with no 24 hour limit*
      </v-card-text>
      Total Pto: {{ $store.getters.user.pto.availablePTO || 0 }}
      <br />
      Used Pto: {{ usedPto }}
      <br />
      Available Pto: {{ availablePto }}
      <v-col>
        <v-text-field
          v-model="pto"
          label="Enter Pto"
          :error-messages="descriptionErrors()"
        >
        </v-text-field>
      </v-col>
      <v-btn
        color="error"
        class="mb-1 mr-2"
        @click="$emit('close')"
      >
        Close
      </v-btn>
      <v-btn
        class="mb-1"
        color="green"
        :disabled="disableDescription"
        @click="$emit('save', pto)"
      >
        SAVE
      </v-btn>
    </v-card>
  </v-card>
</template>
<script>
import moment from 'moment/src/moment';

export default {
  name: 'CashPto',
  data: () => {
    return {
      pto: 0,
      availablePto: 0,
      usedPto: 0,
      alert: {
        display: false,
        message: '',
        confirm: false,
      },
      disableDescription: true,
    };
  },
  mounted() {
    this.getAllUsedPto();
  },
  methods: {
    descriptionErrors() {
      const lastWeek = this.$store.state.shared.dates.last;
      const thisWeek = this.$store.state.shared.dates.present;
      const errors = [];
      if (this.pto % 0.25 !== 0) {
        errors.push('Must be .25 increment');
      }
      if (!this.pto) {
        errors.push('Required');
      }
      if (Number(this.pto) <= 0) {
        errors.push('Must be more then 0');
      }
      if (this.pto > this.availablePto) {
        errors.push('Can\'t be more then available PTO');
      }
      if ((this.$store.getters.dates[1].date === lastWeek[1].date) && moment().format('YYYY-MM-DD') > thisWeek[1].date) {
        errors.push('Can\'t cash PTO for this week, please go to the current week to add');
      }
      this.disableDescription = errors.length > 0;
      return errors;
    },
    getAllUsedPto() {
      //gets all other entries for week, then finds all the pto records and subtracts it from the total pto
      //Job security lol
      const lastPtoUpdate = this.$store.getters.user.pto.date_last_updated.split(' ')[0];
      const dates = this.$store.state.shared.dates;
      const arrayOfAllDatesForPresentWeek = dates.present.map((e) => {
        return e.date;
      });
      const arrayOfAllDatesForLastWeek = dates.last.map((e) => {
        return e.date;
      });
      const allEntriesForWeek = [];
      let allOtherPto = 0;
      //Gets all entries for this week, puts them in a single array
      for (let i = 0; i < 7; i++) {
        const date = arrayOfAllDatesForPresentWeek[i];
        (this.$store.state.weekly.entries[date] || {}).entries.map((e) => {
          return { ...e };
        }).forEach((e) => {
          allEntriesForWeek.push(e);
        });
      }
      //if the most recent pto update is not in the current week, get all pto from last week as well
      if (!arrayOfAllDatesForPresentWeek.includes(lastPtoUpdate)) {
        for (let i = 0; i < 7; i++) {
          const date = arrayOfAllDatesForLastWeek[i];
          (this.$store.state.weekly.entries[date] || {}).entries.map((e) => {
            return { ...e };
          }).forEach((e) => {
            allEntriesForWeek.push(e);
          });
        }
      }
      //With all the entries for the past two week, take just the pto records and add up all the totals
      allEntriesForWeek.forEach((e) => {
        if (e.cstcde === '140.000') {
          allOtherPto += Number(e.total);
        }
      });
      this.usedPto = allOtherPto;
      //Now subtract it from the total pto to get the amount usable
      this.availablePto = Number((this.$store.getters.user.pto.availablePTO - allOtherPto).toFixed(2));
      //set input to the max amount of time allowed, rounded to the nearest one
      this.pto = JSON.stringify(this.availablePto).split('.')[0];
    },
  },

};
</script>
