<template>
  <v-card
    tile
    flat
  >
    <v-data-table
      id="ifta-view-all-table"
      hide-default-footer
      disable-sort
      :headers="headers"
      :items-per-page="-1"
      :item-class="calculateClass"
      :mobile-breakpoint="22000"
      dense
      :items="itemsForTable"
      @click:row="openEntry"
    >
      <template #[`item.start`]="row">
        <v-chip
          :id="`start-${row.index}`"
          :color="row.item.overlap || hasError(row.index, 'start') ? 'red' : ''"
          outlined
        >
          {{ row.item.start_string }}
        </v-chip>
      </template>
      <template #[`item.stop`]="row">
        <v-chip
          :id="`stop-${row.index}`"
          outlined
          :color="row.item.overlap || hasError(row.index, 'stop') ? 'red' : ''"
          class="my-1"
        >
          {{ row.item.stop_string }}
        </v-chip>
      </template>
      <template #[`item.description`]="row">
        <b
          v-if="!row.item.description"
          :id="`description-${row.index}`"
          style="color: red"
        >
          Missing Description
        </b>
        <p
          v-else
          :id="`description-${row.index}`"
          class="mb-0"
          :style="hasError(row.index, 'description') ? 'border: 3px solid red' : ''"
          style="max-width: 55vw"
        >
          {{ row.item.description }}
        </p>
      </template>
      <template #[`item.cstcde`]="row">
        <b
          v-if="!row.item.cstcde"
          :id="`cstcde-${row.index}`"
          style="color: red"
        >
          Missing Cost Code
        </b>
        <p
          v-else
          :id="`cstcde-${row.index}`"
          :style="hasError(row.index, 'cstcde') ? 'border: 3px solid red' : ''"
          class="mb-0"
        >
          {{ row.item.cstcde }}
        </p>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import moment from 'moment/src/moment';

export default {
  name: 'AllEntryView',
  props: {
    allEntries: {
      type: Array,
      required: true,
    },
    validEntries: {
      type: Array,
      required: true,
    },
    otherUsedTime: {
      type: Function,
      required: true,
    },
    overlapping: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: 'Eq #', value: 'eq_num' },
        { text: 'Cost Code', value: 'cstcde' },
        { text: 'Job #', value: 'job_num' },
        { text: 'Description', value: 'description' },
        { text: 'Start', value: 'start' },
        { text: 'Stop', value: 'stop' },
      ],
      itemsForTable: [],
    };
  },
  mounted() {
    this.itemsForTable = this.generateOverlaps(this.allEntries);
  },
  methods: {
    hasError(index, field) {
      const validObj = (this.validEntries[index] || {});
      if (validObj.failed_fields) {
        const failedFields = Object.keys(validObj.failed_fields) || [];
        return (validObj.valid === 0 && failedFields.includes(field));
      }
      return false;
    },
    generateOverlaps(items) {
      // return items;
      return items.map((e, i) => {
        const format = 'hh:mm:ss A';
        const otherTimes = this.otherUsedTime(i);
        e.overlap = this.overlapping(e, otherTimes);
        e.start_string = moment(e.start, format).format(format);
        e.stop_string = moment(e.stop, format).format(format);
        return { ...e };
      });
    },
    openEntry(_entry, som) {
      const ind = som.index + 1;
      this.$emit('open', ind);
    },

    // Calculates the side bar color next to the entry
    calculateClass(item) {
      const index = this.allEntries.findIndex((e, i) => {
        if (item.id) {
          return e.id === item.id || i === item.index;
        }
        return i === item.index;
      });
      if ((this.validEntries[index] || {}).valid === 0) {
        return 'invalid';
      }
      if (item.cstcde === '1015.000') {
        return 'inspectionSide';
      }
      if (item.driving_record === '1') {
        return 'driveSide';
      }
      return 'dutySide';
    },

  },
};
</script>

<style>
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  min-height: 25px;
}
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row  {
  margin-top: 3px;
  margin-bottom: 3px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}
.dutySide {
  border-left: .5rem solid #ff9800;
}
.driveSide {
  border-left: .5rem solid #4caf50;
}
.inspectionSide {
  border-left: .5rem solid #49baf1;
}
.invalid {
  border: 3px solid #ff5252 !important;
}
</style>
