/* eslint-disable no-param-reassign */
import actions from './actions/index';
import getters from './getters/index';

export default {
  state: {
    importedTemplates: [],
    myApps: [],
    unusedApps: [],
  },
  mutations: {
    setImportedTemplates(state, arrayOfImported) {
      state.importedTemplates = arrayOfImported;
    },
    setMyApps(state, apps) {
      state.myApps = apps;
    },
    setUnusedApps(state, apps) {
      state.unusedApps = apps;
    },
  },
  actions,
  getters,
};
