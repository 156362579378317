/* eslint-disable no-param-reassign */
import actions from './actions';
import getters from './getters';

export default {
  state: {
    iftaOverview: [],
    newDate: null,
    iftaEquipment: [],
    iftaTrailers: [],
    selectedRecord: null,
    viewingIftaId: null,
    viewingUnsaved: false,
    unsavedIftaRecords: [], // An array of ifta records, same form as selectedRecord.
    lastUsedInfo: {
      jobs: [],
      equipment: [],
    },
  },
  mutations: {
    set_selected_record(state, record) {
      state.selectedRecord = record;
    },
    setViewIftaId(state, id) {
      state.viewingIftaId = id;
    },
    setUnsavedStatus(state, bool) {
      state.viewingUnsaved = bool;
    },
    setUnsavedRecords(state, records) {
      state.unsavedIftaRecords = records;
    },
    setUnsavedRecord(state, record) {
      const id = record.record.id;
      const unsaved = state.unsavedIftaRecords;

      const index = unsaved.findIndex((r) => {
        return r.record.id === id;
      });

      if (index >= 0) {
        unsaved.splice(index, 1, record);
      } else {
        unsaved.push(record);
      }
    },
    deleteLocallySavedRecord(state, id) {
      const unsaved = state.unsavedIftaRecords;

      const index = unsaved.findIndex((r) => {
        return r.record.id === id;
      });
      if (index >= 0) { // If an index is found, remove it.
        unsaved.splice(index, 1);
      }
    },

    setIftaOverview(state, iftaOverview) {
      state.iftaOverview = iftaOverview;
    },
    setNewDate(state, date) {
      state.newDate = date;
    },
    setIftaEquipment(state, eq) {
      state.iftaEquipment = eq;
    },
    setIftaTrailers(state, eq) {
      state.iftaTrailers = eq;
    },
    setLastUsedInfo(state, last) {
      state.lastUsedInfo = last;
    },
  },
  actions,
  getters,
};
