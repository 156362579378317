/* eslint-disable max-len */
export default {
  methods: {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    fetchDataNew(...args) {
      let cstk = false;
      let userData = false;
      let options = false;
      let timeoutInMS = 30000;
      let url = 'controller.php';
      //if there are two arguments we need to check and see if the 2nd argument is userData or an options object
      if (args.length === 2) {
        if (!args[1]) {
          throw new Error('Arguments should never be an empty string, null, or false. If the argument is not needed, it should not be included.');
        }
        if (this.verifyOptionsObject(args[1])) {
          options = args[1];
        } else {
          userData = args[1];
        }
      //if there are three arguments we need to check and ensure that the 2nd argument is userData and that the 3rd is an options object
      } else if (args.length === 3) {
        if (!args[1] || !args[2]) {
          throw new Error('Arguments should never be an empty string, null, or false. If the argument is not needed, it should not be included.');
        }
        if (this.verifyOptionsObject(args[2])) {
          options = args[2];
          userData = args[1];
        } else {
          throw new Error(
            'Max arguments = 3. 1st is always cstk. '
            + 'If 2 arguments, the 2nd can be options or userData. '
            + 'If 3 arguments the 2nd must be userData and the 3rd must be options.',
          );
        }

      //if there are more than three arguments, we throw an error
      } else if (arguments.length > 3) {
        throw new Error(
          'Max arguments = 3. 1st is always cstk. '
          + 'If 2 arguments, the 2nd can be options or userData. '
          + 'If 3 arguments the 2nd must be userData and the 3rd must be options.',
        );
      }

      //the first argument must always be the CSTK and
      //can be an array with four strings or an object with keys
      //(center,section,tab,kind) and all values being a string.
      cstk = this.validateAndReturnArrayContainingCSTK(args[0]);
      //ask the browser if it thinks it is offline
      if (navigator && (!navigator.onLine)) {
        return {
          error: true,
          errorMessage: 'This device appears to be offline.',
          networkError: true,
          empty: false,
          actualResponse: null,
          unauthenticated: null,
        };
      }
      const postData = this.createPostStringNewAPI(cstk, userData);

      if (options && options.fd_timeout) {
        if (!Number.isInteger(options.fd_timeout)) {
          throw new Error('Option fd_timeout must be an integer greater than 0.');
        }
        timeoutInMS = options.fd_timeout;
      }

      if (options && options.alternate_url) {
        if (typeof options.alternate_url !== 'string') {
          throw new Error('The option (alternate_url) must be a url of type = string.');
        }
        url = options.alternate_url;
      }

      //create abort controller
      const controller = new AbortController();
      //create abort signal
      const { signal } = controller;
      //send signal with fetch request
      const fetchPromise = fetch(url, {
        signal,
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8;',
        },
        body: postData,
      });
      //create a timeout for the abort
      setTimeout(() => {
        return controller.abort();
      }, timeoutInMS);
      //return status code and attempt JSON parse on response
      return fetchPromise.then((response) => {
        return Promise.all([response.status, response.json()]);
      })
        //call classifyFetchResponse with status code and parsed JSON
        .then((response) => {
          return this.classifyFetchResponse(response[0], response[1]);
        })
        //return generic error message for unexpected failures
        .catch((e) => {
          if (e.name === 'AbortError') {
            return {
              error: true,
              errorMessage: `Network request was aborted after waiting ${timeoutInMS} miliseconds.`,
              networkError: true,
              empty: false,
              actualResponse: null,
              unauthenticated: null,
            };
          }
          return {
            error: true,
            errorMessage: 'An unknown error has occurred.',
            networkError: false,
            empty: false,
            actualResponse: null,
            unauthenticated: null,
          };
        });
    },
    //here we are verifying that an options object only contains keys that are in the validKeys array
    verifyOptionsObject(obj) {
      let containsAtLeastOneValidOption = false;
      const validKeys = ['fd_timeout', 'alternate_url'];
      const objectKeys = Object.keys(obj);
      for (let i = 0; i < objectKeys.length; i++) {
        if (!validKeys.includes(objectKeys[i])) {
          return false;
        }
        containsAtLeastOneValidOption = true;
      }
      return containsAtLeastOneValidOption;
    },
    validateAndReturnArrayContainingCSTK(unvalidatedCSTK) {
      const errorMessage = 'CSTK argument can be an array with four strings or an object with keys '
                         + '(center,section,tab,kind) and all values being a string.';
      if (Array.isArray(unvalidatedCSTK) && unvalidatedCSTK.length === 4) {
        for (let i = 0; i < 4; i++) {
          if (typeof unvalidatedCSTK[i] !== 'string') {
            throw new Error(errorMessage);
          }
        }
        return unvalidatedCSTK;
      }
      if (typeof unvalidatedCSTK !== 'object' || (Object.keys(unvalidatedCSTK).length !== 4)) {
        throw new Error(errorMessage);
      }
      const validKeys = ['center', 'section', 'tab', 'kind'];
      const objectKeys = Object.keys(unvalidatedCSTK);
      for (let i = 0; i < objectKeys.length; i++) {
        if (!validKeys.includes(objectKeys[i]) || typeof unvalidatedCSTK[objectKeys[i]] !== 'string') {
          throw new Error(errorMessage);
        }
      }

      return [unvalidatedCSTK.center,
        unvalidatedCSTK.section,
        unvalidatedCSTK.tab,
        unvalidatedCSTK.kind,
      ];
    },
    //validate response here to minimize boiler plate code for each API call
    classifyFetchResponse(statusCode, parsedJSON) {
      const returnObj = {
        error: null,
        errorMessage: false,
        networkError: false,
        empty: null,
        response: parsedJSON,
        unauthenticated: null,
      };
      //successful response
      if (statusCode === 200 && parsedJSON.status && parsedJSON.status === 'success') {
        returnObj.error = false;
        returnObj.empty = false;
        returnObj.unauthenticated = false;
      //"empty" response
      } else if (statusCode === 404 && parsedJSON.status && parsedJSON.status === 'success' && parsedJSON.message && parsedJSON.message === 'empty') {
        returnObj.error = false;
        returnObj.empty = true;
        returnObj.unauthenticated = false;
      //user is unauthenticated according to the Centers
      } else if (statusCode === 200 && parsedJSON.message && parsedJSON.message === 'unauthenticated') {
        returnObj.error = true;
        returnObj.empty = false;
        returnObj.unauthenticated = true;
      } else {
        // server error or client did not provide a valid request
        // or we're unsure of what happened
        returnObj.error = true;
        returnObj.empty = false;
        returnObj.unauthenticated = false;
      }
      return returnObj;
    },
    createPostStringNewAPI(cstk, userData) {
      const version = localStorage.getItem('cur_version');
      let convertToParamsString = `center=${cstk[0]}&section=${cstk[1]}&tab=${cstk[2]}&kind=${cstk[3]}&dataType=json&app-version=${version}`;
      if (userData) {
        convertToParamsString = `${convertToParamsString}&userData=${encodeURIComponent(JSON.stringify(userData))}`;
      }
      return convertToParamsString;
    },
  },
};
