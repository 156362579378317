import fetchDataNewAPI from '../../../../mixins/fetchDataNewAPI';
// import fetchData from '../../../../utils/fetchData';

export default {
  async fetchDataNew(apicstk, userData, server) {
    //If you dont send userdata, it will fix itself
    if (!server) {
      const newServer = userData;
      return fetchDataNewAPI.methods.fetchDataNew(apicstk, {}, { alternate_url: `${newServer}controller.php` });
    }
    return fetchDataNewAPI.methods.fetchDataNew(apicstk, userData, { alternate_url: `${server}controller.php` });
  },

  getAllImportedTemplates(server) {
    const importedTemplatesCstk = {
      center: 'user',
      section: 'return',
      tab: 'all',
      kind: 'importedTemplates',
    };
    return this.fetchDataNew(importedTemplatesCstk, server);
  },
  getAllAvailableCstks(server) {
    const allCstksCstk = {
      center: 'user',
      section: 'return',
      tab: 'all',
      kind: 'cstksAvailableToLoggedInUser',
    };
    const userData = {
      returnFormat: 'allArray',
    };
    return this.fetchDataNew(allCstksCstk, userData, server);
  },
};
