import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,{staticClass:"justify-center"},[_c('h3',[_vm._v("Add Injury To Week")])]),_c(VCardText,[_c(VContainer,[_c(VTextarea,{attrs:{"id":"injury-form-input","value":_vm.injury.injury_comment,"outlined":"","label":"Describe Event","counter":"80"},on:{"input":_vm.handleDescription}}),_c(VCheckbox,{attrs:{"label":"Supervisor Notified","input-value":_vm.injury.notified_supervisor,"true-value":"1","false-value":"0"},on:{"change":_vm.handleNotified}})],1)],1),_c(VCardActions,{staticClass:"d-flex justify-end"},[_c(VBtn,{attrs:{"id":"cancelSave","color":"warning"},on:{"click":function($event){return _vm.$emit('closeInjury')}}},[_vm._v(" Close ")]),_c(VBtn,{attrs:{"color":"success","loading":_vm.saving,"disabled":_vm.$v.$invalid},on:{"click":_vm.saveInjury}},[_vm._v(" Save ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }