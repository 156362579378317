import fetchDataNewAPI from '../../../../mixins/fetchDataNewAPI';
import fetchData from '../../../../utils/fetchData';

export default {
  async fetchDataNew(apicstk, userData, server) {
    //If you dont send userdata, it will fix itself
    if (!server) {
      const newServer = userData;
      return fetchDataNewAPI.methods.fetchDataNew(apicstk, {}, { alternate_url: `${newServer}controller.php` });
    }
    return fetchDataNewAPI.methods.fetchDataNew(apicstk, userData, { alternate_url: `${server}controller.php` });
  },

  getAllAvailableCstks(server) {
    const allCstksCstk = {
      center: 'user',
      section: 'return',
      tab: 'all',
      kind: 'cstksAvailableToLoggedInUser',
    };
    const userData = {
      returnFormat: 'visibleArray',
    };
    return this.fetchDataNew(allCstksCstk, userData, server);
  },
  saveDefaults(defaults, server) {
    const defaultsCstk = {
      center: 'employee',
      section: 'set',
      tab: 'single',
      kind: 'defaultsForEmployee',
    };
    return fetchData(
      defaultsCstk,
      null,
      defaults,
      server,
    );
  },
  getLatestAppVersion(server) {
    const apiCstk = {
      center: 'user', section: 'return', tab: 'single', kind: 'latestVersionApp',
    };
    return fetchData(apiCstk, null, {}, server);
  },
};
