/* eslint-disable no-param-reassign */

import actions from './actions';
import getters from './getters';

export default {
  state: {
    crusherJobs: [],
    publicJobs: [],
    isSalaried: false,
    entries: {},
    timecardEntry: {},
    quickRecord: {},
    defaults: {},
    offline: {},
    lastUsedInfo: {
      jobs: [],
      equipment: [],
    },
    comment: {
      last: { comment: '' },
      present: { comment: '' },
    },
    injury: {
      last: {
        injury_comment: null,
        injury_date: null,
        notified_supervisor: '0',
      },
      present: {
        injury_comment: null,
        injury_date: null,
        notified_supervisor: '0',
      },
    },
    holidays: [],
    selectedDay: null,
    week: 'present',
  },
  mutations: {
    setCrusherJobs(state, jobs) {
      state.crusherJobs = jobs;
    },
    setPublicJobs(state, jobs) {
      state.publicJobs = jobs;
    },
    setIsSalaried(state, status) {
      state.isSalaried = status;
    },
    setEntries(state, entries) {
      state.entries = entries;
    },
    setDefaults(state, defaults) {
      state.defaults = defaults;
    },
    setComment(state, comment) {
      state.comment = comment;
    },
    setInjury(state, injury) {
      state.injury = injury;
    },
    setOfflineEntries(state, entries) {
      state.offline = entries;
    },
    setLastUsedInfo(state, last) {
      state.lastUsedInfo = last;
    },
    setHolidays(state, holidays) {
      state.holidays = holidays;
    },
    setSelectedDay(state, day) {
      state.selectedDay = day;
    },
    saveWeek(state, week) {
      state.week = week;
    },
    setTimecardEntry(state, entry) {
      state.timecardEntry = entry;
    },
    setQuickRecord(state, entry) {
      state.quickRecord = entry;
    },
  },
  actions,
  getters,
};
