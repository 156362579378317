import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pt-0"},[_c(VDataTable,{attrs:{"id":"records-table","single-expand":"","items":_vm.allEntries,"headers":_vm.headers,"expanded":_vm.expanded,"disable-sort":"","disable-pagination":"","disable-filtering":"","hide-default-footer":"","mobile-breakpoint":0,"no-data-text":"No Time Entries for this Day"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{class:{
          'savedEntry': props.item.id,
          'recordSubmitted': props.item.submitted,
          'offlineEntry': props.item.offline,
        },on:{"click":function($event){return _vm.expand(props)}}},[_c('td',[_vm._v(_vm._s(props.item.job_num || ' - - '))]),_c('td',[_vm._v(_vm._s(props.item.cstcde))]),_c('td',[_vm._v(_vm._s(props.item.total))]),_c('td',[_vm._v(_vm._s(props.item.eq_num || ' - - '))])])]}},{key:"expanded-item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"pl-0 pr-0",attrs:{"colspan":"4"}},[_c(VContainer,{staticClass:"d-flex justify-space-between pl-0 pr-0"},[_c(VBtn,{attrs:{"id":"delete-btn","color":"error","outlined":"","loading":_vm.deleting,"disabled":props.item.submitted=== props.item.emp_num,"small":""},on:{"click":function($event){return _vm.confirmDelete(props.item)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-delete ")]),_vm._v(" Delete ")],1),(props.item.offline)?_c(VBtn,{staticClass:"ml-2",attrs:{"id":"send-btn","small":"","outlined":"","disabled":props.item.total > 24},on:{"click":function($event){return _vm.editExistingReport(props.item)}}},[_vm._v(" Send ")]):_c(VBtn,{staticClass:"ml-2",attrs:{"id":"edit-btn","disabled":props.item.submitted === props.item.emp_num || props.item.total > 24,"outlined":"","small":""},on:{"click":function($event){return _vm.editExistingReport(props.item)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Edit ")],1)],1)],1)])]}},{key:"footer",fn:function(){return [_c(VContainer,{staticClass:"d-flex justify-end pr-0",attrs:{"fluid":""}},[_c(VBtn,{attrs:{"id":`add-time-btn-${_vm.returnDateName(_vm.date)}`,"light":"","small":""},on:{"click":function($event){return _vm.addEntryToDay(_vm.date)}}},[_c(VIcon,{attrs:{"color":"success","left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" add time ")],1)],1)]},proxy:true}])}),_c('Alertbox',{ref:"alertbox"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }