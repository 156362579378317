<template>
  <div>
    <v-container fluid>
      <v-sheet
        shaped
        elevation="0"
        color="#e6e6e6"
        class="pa-3"
      >
        <v-row
          dense
          justify="space-between"
          class="ma-0"
        >
          <v-col class="pa-1">
            <v-btn
              :loading="loading"
              color="success"
              block
              large
              @click="recordClickedOn = {}, dialog = true"
            >
              Add New Fuel Entry
            </v-btn>
          </v-col>

          <v-col class="pa-1">
            <v-btn
              :disabled="savedFuelerEntries.length === 0"
              :loading="loading"
              color="primary"
              block
              large
              @click="submitRecords()"
            >
              <v-icon left>
                mdi-cloud-upload-outline
              </v-icon>
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-sheet>
      <v-divider class="mt-4" />
      <v-data-table
        :headers="headers"
        :items="sortedAllRecords"
        :mobile-breakpoint="0"
        :loading="loading"
        :items-per-page="-1"
        :height="getTableHeight"
        dense
        hide-default-footer
        fixed-header
      >
        <template
          #item="row"
        >
          <tr
            :class="getFuelEntryRowClass(row.item)"
            @click="handleClickRow(row.item)"
          >
            <td class="td-body">
              <v-btn
                v-if="!row.item.submitted"
                color="error"
                icon
                @click.stop="removeEntry(row.item)"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </td>
            <td class="td-body">
              {{ row.item.eq_num }}
            </td>
            <td class="td-body">
              {{ row.item.primary_unit_measure === 0
                ? `${row.item.current_mile || 0 } Miles`
                :`${row.item.current_hour || 0 } Hours` }}
            </td>
            <td class="td-body">
              {{ row.item.gallon }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-divider class="mb-2" />
      <v-row
        class="ma-0"
        justify="space-between"
      >
        <v-col
          class="pa-1"
          cols="12"
          md="4"
        >
          <b class="text-center">{{ `Total gallons for today: ${totalGallonsForDay}` }}</b>
        </v-col>
        <v-col
          class="pa-1"
          cols="6"
          sm="3"
          md="2"
        >
          <div class="align-center d-flex flex-nowrap">
            <v-sheet
              width="16"
              height="16"
              class="mr-2"
              tile
              color="#99ccff"
            ></v-sheet>
            <span class="grey--text mr-4 text--darken-2">Submitted</span>
          </div>
        </v-col>

        <v-col
          class="pa-1"
          cols="6"
          sm="3"
          md="2"
        >
          <div class="align-center d-flex flex-nowrap">
            <v-sheet
              width="16"
              height="16"
              class="mr-2"
              tile
              color="#f0f1b0"
            ></v-sheet>
            <span class="grey--text mr-4 text--darken-2">Saved</span>
          </div>
        </v-col>

        <v-col
          class="pa-1"
          cols="6"
          sm="3"
          md="2"
        >
          <div class="align-center d-flex flex-nowrap">
            <v-sheet
              width="16"
              height="16"
              class="mr-2"
              tile
              color="rgb(225, 160, 225)"
            ></v-sheet>
            <span class="grey--text mr-4 text--darken-2">Offline</span>
          </div>
        </v-col>

        <v-col
          class="pa-1"
          cols="6"
          sm="3"
          md="2"
        >
          <div class="align-center d-flex flex-nowrap">
            <v-sheet
              width="16"
              height="16"
              class="mr-2 outline-invalid-shadow"
              color="white"
            ></v-sheet>
            <span class="grey--text text--darken-2">Invalid</span>
          </div>
        </v-col>
        <v-col
          class="pa-1"
          cols="12"
          md="6"
        >
          <span class="grey--text text--darken-2">* Saved and Offline records are only stored on this device</span>
        </v-col>
      </v-row>
    </v-container>
    <Alertbox ref="alertbox" />
    <v-dialog
      v-model="dialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card
        v-if="dialog"
        class="m-auto"
        style="width:80%;margin:auto"
      >
        <entryPage
          v-if="dialog"
          :logged-in-user="loggedInUser"
          :locations="locations"
          :equipment="equipment"
          :record-clicked-on="recordClickedOn"
          :selected-equipment="selectedEquipment"
          :all-records="allRecords"
          @alert="handleEntryAlert"
          @close="dialog = false; refreshData()"
          @save="saveEntry"
          @remove="removeEntry"
        />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import fetchDataNewAPI from '../../mixins/fetchDataNewAPI.js';
import entryPage from './entryPage';
import Alertbox from '../alert/alert.vue';

export default {
  name: 'FuelerPg',
  components: {
    entryPage,
    Alertbox,
  },
  mixins: [fetchDataNewAPI],
  data() {
    return {
      loading: false,
      selectedDate: new Date().toISOString().substring(0, 10),
      dateMenu: false,
      loggedInUser: 0,
      equipment: [],
      locations: [],
      recordClickedOn: {},
      selectedEquipment: '-1',
      savedFuelerEntries: [],
      allRecords: [],
      online: true,
      showOnlyMyRecords: false,
      existingRecord: false,
      dialog: false,
      headers: [
        {
          text: '', value: 'actions', align: 'center', width: '12px', sortable: false,
        },
        {
          text: 'Equipment #', value: 'eq_num', align: 'center', sortable: false,
        },
        {
          text: 'Current Hours/Miles', value: 'current_hour', align: 'center', sortable: false,
        },
        {
          text: 'Gallons', value: 'gallon', align: 'center', sortable: false,
        },
      ],
    };
  },
  computed: {
    sortedAllRecords() {
      // Put all saved records at the top (submitted on the bottom)
      // Then, sort by date
      return this.allRecords.slice().sort((a, b) => {
        if (a.submitted === b.submitted) {
          return a.date < b.date ? 1 : -1;
        }
        return a.submitted ? 1 : -1;
      });
    },
    getTableHeight() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return 'calc(100vh - 400px)';
      }
      if (this.$vuetify.breakpoint.smOnly) {
        return 'calc(100vh - 300px)';
      }
      return 'calc(100vh - 250px)';
    },
    storageKey() {
      const username = this.$store.getters.user.username;
      if (!username) {
        return 'fueler';
      }
      return `${username}:fueler`;
    },
    size() {
      const size = {
        xs: 'x-small', sm: 'x-small',
      }[this.$vuetify.breakpoint.name];
      return size ? { [size]: true } : {};
    },
    server() {
      return `${this.$store.getters.server}controller.php`;
    },
    totalGallonsForDay() {
      return this.allRecords.reduce((acc, e) => {
        return acc + Number(e.gallon || 0);
      }, 0);
    },
  },
  async created() {
    this.loading = true;
    this.online = this.$store.getters.online;

    if (this.online) {
      const getLocations = await this.getLocations();
      const getEquipment = await this.getEquipment();
      if (!getLocations || !getEquipment) {
        this.userAlert('Unable to gather equipment/records, please navigate to a different part '
          + 'of the app and then try to reopen the Fueler portion.');
      }
    }

    const user = this.$store.getters.user;
    this.loggedInUser = user.emp_num;
    this.locations = this.$store.getters.fuelerLocation;
    this.equipment = this.$store.getters.fuelerEquipment;

    if (!user) {
      this.userAlert('You must be online the first time you use this app');
    } else {
      this.refreshData();
    }
    this.loading = false;
  },

  methods: {
    getLocalStorage(name, fallback) {
      const item = localStorage.getItem(name);
      return item ? JSON.parse(item) : fallback;
    },

    setLocalStorage(name, value) {
      localStorage.setItem(name, JSON.stringify(value));
    },

    handleEntryAlert(alertObj) {
      if (alertObj?.display) {
        this.userAlert(alertObj.message, false, true);
      }
    },

    async userAlert(message, confirm = false, allowLinks = false) {
      return this.$refs.alertbox.show(message, confirm, allowLinks);
    },

    async submitRecords() {
      const savedRecords = this.savedFuelerEntries;

      if (this.online) {
        for (let i = 0; i < savedRecords.length; i++) {
          const record = savedRecords[i];
          if (record.valid === false) {
            this.userAlert('One or more records are invalid,\nplease correct them before submitting\n\n'
              + 'Invalid records are outlined in red');
            return;
          }
        }

        const unsubmittedRecords = savedRecords.filter((record) => {
          return !record.submitted;
        });

        this.loading = true;
        const promises = [];
        for (let i = 0; i < unsubmittedRecords.length; i++) {
          promises.push(this.setFuelReport(unsubmittedRecords[i]));
        }
        await Promise.all(promises);
        this.setSavedFuelEntries([]);
        this.loading = false;
      } else {
        this.userAlert('You must be online to submit records');
      }

      this.refreshData();
    },

    removeEntry(entry) {
      const savedRecords = this.savedFuelerEntries;
      savedRecords.splice(entry.index, 1);
      this.setSavedFuelEntries(savedRecords);
      this.dialog = false;
      this.refreshData();
    },

    saveEntry(entry) {
      const savedRecords = this.savedFuelerEntries;
      if (entry.index !== null && entry.index !== 'undefined') {
        savedRecords[entry.index] = entry;
      } else {
        savedRecords.push(entry);
      }

      this.setSavedFuelEntries(savedRecords);
      this.dialog = false;
      this.refreshData();
    },

    async refreshData() {
      this.loading = true;
      this.online = this.$store.getters.online;
      const savedRecords = this.getLocalStorage(`${this.storageKey}:fuelerEntries`, []);

      for (let i = 0; i < savedRecords.length; i++) {
        savedRecords[i].index = i;
        savedRecords[i].submitted = false;
      }

      this.setSavedFuelEntries(savedRecords);

      let tempAllRecords = this.$store.getters.fuelerEntriesOnline;

      if (this.online) {
        tempAllRecords = await this.getLastRecords();
        if (!tempAllRecords) {
          this.userAlert('Unable to get past records, please navigate to a different part of the app '
            + 'and then try to reopen the Fueler portion.');
          this.loading = false;
          return;
        }
        this.$store.commit('setOnlineFuelerRecords', tempAllRecords);
      }

      this.allRecords = [...tempAllRecords, ...savedRecords];
      this.loading = false;
    },

    handleClickRow(value) {
      this.online = this.$store.getters.online;

      if ((!this.online && value.offline) || this.online) {
        this.existingRecord = true;
        this.recordClickedOn = { ...value };
        this.dialog = true;
      }
    },

    getFuelEntryRowClass(item) {
      let classes = '';
      if (item.valid === false) {
        classes += 'outline-invalid ';
      }

      if (item.offline) {
        classes += 'bg-offline ';
      } else if (!item.submitted) {
        classes += 'bg-saved ';
      } else {
        classes += 'bg-submitted ';
      }
      return classes;
    },

    async getLastRecords() {
      const cstk = ['equipment', 'return', 'all', 'recentFuelRecordsForEmployee'];
      const userData = {
        emp_num: this.loggedInUser,
        date: this.selectedDate,
      };
      const response = await this.fetchDataNew(cstk, userData, {
        alternate_url: this.server,
      });
      if (response.unauthenticated) {
        this.$store.dispatch('logout');
      }
      if (!response.error && !response.empty) {
        return response.response.data.map((e) => {
          return {
            ...e,
            submitted: true,
          };
        });
      }
      if (response.empty) {
        return [];
      }
      return false;
    },

    getLocations() {
      const cstk = [
        'employee',
        'return',
        'all',
        'recentJobsWithLocationsUsedByEmployeeOnTimecardAndTimecardJobs',
      ];
      const userData = {
        emp_num: this.loggedInUser,
      };
      return this.fetchDataNew(cstk, userData, {
        alternate_url: this.server,
      }).then((response) => {
        if (response.unauthenticated) {
          this.$store.dispatch('logout');
        }
        if (!response.error && !response.empty) {
          for (let i = 0; i < Object.keys(response.response.data).length; i++) {
            const calc = `${response.response.data[i].job_num} - ${response.response.data[i].description}`;
            response.response.data[i].description = calc;
          }
          this.$store.commit('setfuelerLocation', response.response.data);
          return true;
        }
        return false;
      });
    },

    async getEquipment() {
      const arr = [];
      for (let i = 0; i < this.$store.getters.equipment.length; i++) {
        const e = this.$store.getters.equipment[i];
        const obj = {
          ...e,
          description: `${e.eq_num} - ${e.description}`,
        };
        arr.push(obj);
      }

      this.$store.commit('setfuelerEquipment', arr);
      return true;
    },

    setSavedFuelEntries(entries) {
      this.setLocalStorage(`${this.storageKey}:fuelerEntries`, entries);
      this.savedFuelerEntries = entries;
    },

    async setFuelReport(entry) {
      const cstk = ['equipment', 'set', 'single', 'fuelReport'];

      // conver mile or hour to 0 if null
      const fEntry = {
        ...entry,
        current_hour: entry.current_hour || '0',
        current_mile: entry.current_mile || '0',
      };

      return this.fetchDataNew(cstk, fEntry, {
        alternate_url: this.server,
      }).then((response) => {
        if (response.unauthenticated) {
          this.$store.dispatch('logout');
        }
        return (!response.error && !response.empty);
      });
    },
  },
};
</script>

<style>
table {
  border-collapse: collapse;
}

.v-data-table__wrapper {
  padding-right: 1px; /* must equal the width of the border in .outline-invalid */
}
.v-messages__message {
  font-size: 1.2em;
}

.bg-offline {
  background-color: rgb(225, 160, 225);
}
.bg-saved {
  background-color: #f0f1b0;
}
.bg-submitted {
  background-color: #99ccff;
}
.td-body {
  text-align: center;
  font-size: 14px !important;
}
.td-footer {
  text-align: center;
  font-size: 0.75rem !important;
  padding: 8px;
}
.outline-invalid {
  border: 2px solid red !important;
  padding-right: 1px;
  padding-left: 1px;
}
.outline-invalid-shadow {
  box-shadow: 0 0 0 2px red !important;
}
</style>
