import moment from 'moment/src/moment';
import router from '@/router';
import shared from '../api';

export default {
  // MAIN REFRESH CALL
  // Gets all required information for app to run. called on open, and refresh btn click
  async initialize({ dispatch, commit }) {
    commit('setLoading', true);
    commit('setCurrentVersion');
    commit('setNewDate', null); // Reset new date each time the app is initialized, should result in proper date handling
    await dispatch('getDates');

    const response = await Promise.all([
      dispatch('getJobs'),
      dispatch('getEquipment'),
      dispatch('getCostCodes'),
      dispatch('getJobEquipment'),
      dispatch('getRepairCodes'),
      dispatch('getCrusherJobs'),
      dispatch('getAllPublicJobs'),
      dispatch('setImportedTemplates'),
      dispatch('getEmployeeSalariedStatus'),
    ]);
    const errors = response.includes(false);
    if (errors) {
      dispatch('updateStatus', false); // Call failed, go offline
      commit('setSnackbar', {
        show: true,
        time: 2000,
        message: 'There was an error getting data, click refresh button on top of app.',
      });
    }
    // After running the heavy calls, run the lighter calls, found in the resume function
    await dispatch('resume');

    commit('setLoading', false);
    return !errors;
  },

  // Runs some lighter calls on resume, no need to get equipment/jobs/etc
  async resume({ dispatch, commit }) {
    await dispatch('getUser');
    const response = await Promise.all([
      dispatch('getInjury'),
      dispatch('getDefaults'),
      dispatch('getHolidays'),
      dispatch('getLastUsedInfo'),
      dispatch('getComments'),
      dispatch('getPTO'),
      dispatch('getEntries'),
    ]);
    const errors = response.includes(false);
    if (errors) {
      commit('setSnackbar', {
        show: true,
        time: 2000,
        message: 'There was an error getting personal data',
      });
    }
    return !errors;
  },

  makeDates(_context, dateToUse) {
    const startOfWeek = moment(dateToUse).startOf('week');
    const endOfWeek = moment(dateToUse).endOf('week');
    const days = [];
    let day = startOfWeek;
    while (day <= endOfWeek) {
      days.push({ date: day.format('YYYY-MM-DD'), dayName: day.format('dddd') });
      day = day.clone().add(1, 'd');
    }
    return days;
  },
  async getDates({ commit, dispatch }) {
    const present = await dispatch('makeDates', moment().format('YYYY-MM-DD'));
    const last = await dispatch('makeDates', moment().subtract(7, 'd').format('YYYY-MM-DD'));
    const dates = {
      last,
      present,
    };
    commit('setDates', dates);
  },
  updateStatus({ commit }, online) {
    commit('setOnline', online);
    if (online) {
      commit('setSnackbar', {
        show: true,
        time: 2000,
        message: 'Reconnected...',
        color: 'Primary',
      });
    } else {
      commit('setSnackbar', {
        show: true,
        time: 2000,
        message: 'You have gone offline',
        color: '#553882',
      });
    }
  },

  //API SERVER URL
  setServer({ commit }, url) {
    commit('setServer', url);
  },

  async getUser({ commit, getters }) {
    const response = await shared.getUser(getters.server);
    if (response.unauthenticated) {
      this.dispatch('logout');
      return false;
    }
    if (!response.error && !response.empty) {
      commit('setUser', response.response.data);
      return true;
    }
    return false;
  },
  async getJobs({ commit, getters }) {
    const response = await shared.getJobs(getters.server);
    if (response.unauthenticated) {
      this.dispatch('logout');
    }
    if (!response.error) {
      commit('setJobs', response.response.data);
      return true;
    }
    return false;
  },
  async getEmployees({ commit, getters }) {
    const response = await shared.getEmployees(getters.server);
    if (response.unauthenticated) {
      this.dispatch('logout');
    }
    if (!response.error && !response.empty) {
      commit('setEmployees', response.response.data);
      return true;
    }
    return false;
  },
  async getCostCodes({ commit, getters }) {
    const response = await shared.getCostcodes(getters.server);
    if (!response.error) {
      const costcodes = response.response.data;
      const obj = {};
      costcodes.forEach((e) => {
        const key = e.job_num;
        (obj[key] = obj[key] || []).push(e);
      });
      commit('setCostCodes', obj);
      return true;
    }
    return false;
  },
  async getEquipment({ commit, getters }) {
    const response = await shared.getEquipment(getters.server);
    if (response.unauthenticated) {
      this.dispatch('logout');
    }
    if (!response.error && !response.empty) {
      commit('setEquipment', response.response.data);
      return true;
    }
    return false;
  },
  async getJobEquipment({ commit, getters }) {
    const response = await shared.getJobEquipment(getters.server);
    if (response.message === 'unauthenticated') {
      this.dispatch('logout');
    }
    if (response.message === 'success' && response.status === 'success') {
      const obj = {};
      response.data.forEach((eq) => {
        const equip = {
          description: eq.description,
          eq_num: eq.eq_num,
          job_num: eq.job_num,
        };
        (obj[equip.job_num] = obj[equip.job_num] || []).push(equip);
      });
      commit('setJobEquipment', obj);
      return true;
    }
    return false;
  },
  async getRepairCodes({ commit, getters }) {
    const response = await shared.getRepairCostcodes(getters.server);
    if (response.unauthenticated) {
      this.dispatch('logout');
    }
    if (!response.error) {
      const codes = response.response.data ? response.response.data.map((code) => {
        return code.costcode_num;
      }) : [];
      commit('setRepairCodes', codes);
      return true;
    }
    return false;
  },
  logout() {
    fetch(`${this.getters.server}controller.php?task=logout&dataType=json`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8;',
      },
    }).catch((e) => {
      return `error logging out${e}`;
    });
    this.commit('setUser', {});
    // this.commit('setMyApps', []);
    router.push('/login').catch(() => {});
  },
};
