<template>
  <v-navigation-drawer
    id="navDrawer"
    v-model="$store.getters.navigation.display"
    width="230px"
    hide-overlay
    app
  >
    <v-col class="d-flex justify-end pa-0">
      <v-btn
        dense
        depressed
        text
        color="success"
        @click="$store.dispatch('setNavigation', {display:false})"
      >
        Close
        <v-icon>
          mdi-keyboard-backspace
        </v-icon>
      </v-btn>
    </v-col>
    <v-list
      nav
      class="pt-0"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ $store.getters.user.first_name
              ? `${$store.getters.user.first_name } ${ $store.getters.user.last_name}` : '' }}
            <v-btn
              href="mailto: howard.redinger@rotschyinc.com?subject=App Help"
              class="mb-1 ml-1"
              x-small
              icon
            >
              <v-icon>mdi-help-circle</v-icon>
            </v-btn>
          </v-list-item-title>
          <v-list-item-subtitle>
            Employee Number: {{ $store.getters.user.emp_num }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            App Version: {{ $store.getters.currentVersion }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="isTestServer">
            <span class="red--text">
              Server: {{ $store.getters.server }}
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider v-if="$store.getters.updateApp.update"></v-divider>
      <v-list-item v-if="$store.getters.updateApp.update">
        <v-list-item-content>
          <v-list-item-title>
            Version {{ $store.getters.latestVersion }} available.
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-btn
              color="primary"
              small
              @click="$store.dispatch('setUpdateApp', {display:true})"
            >
              Update App
              <v-icon
                right
                small
              >
                mdi-open-in-new
              </v-icon>
            </v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <!-- Native apps -->
      <v-list-item
        v-for="(info, i) in $store.getters.myApps"
        :id="info.kind"
        :key="`nativeList-${i}`"
        exact
        :disabled="!$store.getters.online && info.center !== 'app'"
        @click="route(info)"
      >
        <v-list-item-icon>
          <v-icon>{{ info.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ info.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- Centers -->
      <v-list-item
        to="/home"
      >
        <v-list-item-icon>
          <v-icon> mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Home
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template #append>
      <v-list-item
        id="openDefaultsDialogBtn"
        link
        @click="$store.commit('setDefaultsDialog', true)"
      >
        <v-list-item-icon>
          <v-icon>mdi-cog</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <div class="pa-2">
        <v-btn
          id="logout"
          color="red"
          block
          @click="logout"
        >
          Logout
        </v-btn>
      </div>
      <!-- DIALOG FOR SETTINGS -->
      <!-- This must stay inside the nav drawer, to stop it from closing -->
      <v-dialog
        v-model="$store.getters.defaultsDialog"
        persistent
        overflow
        width="500px"
        max-width="90%"
        class="mx-5"
      >
        <v-card
          min-height="60vh"
        >
          <v-container fluid>
            <v-tabs
              v-model="tab"
              grow
            >
              <v-tab>timecard</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <timecardDefaults
                  v-if="$store.getters.defaultsDialog"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="error"
              @click="closeDialog"
            >
              cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-navigation-drawer>
</template>

<script>
/**
 * @descript NavDrawer | Scoped to the navigation bar,
 * the nav drawer is set to visable via the global
 * store
 */
import timecardDefaults from './timecardDefaults';

export default {
  name: 'NavDrawer',
  components: {
    timecardDefaults,
  },
  data() {
    return {
      tab: true,
    };
  },
  computed: {
    isTestServer() {
      return this.$store.getters.server.includes('test');
    },
  },
  methods: {
    logout() {
      localStorage.setItem('logout', 'true');
      this.$store.dispatch('logout');
    },
    closeDialog() {
      this.$store.commit('setDefaultsDialog', false);
    },
    route(app) {
      if (app.center !== 'app' && app.section !== 'app' && app.tab !== 'app') {
        this.pushRoute(`/centers/${app.center}/${app.section}/${app.tab}/${app.kind}`);
      } else {
        this.pushRoute(app.kind);
      }
    },
    pushRoute(route) {
      if (route && this.$route.path !== route && this.$route.path !== `/${route}`) {
        if (!route.startsWith('/')) {
          this.$router.push(`/${route}`).catch(() => {});
        } else {
          this.$router.push(route).catch(() => {});
        }
      }
    },
  },

};
</script>
