export default {
  importedTemplates(state) {
    return state.importedTemplates;
  },
  myApps(state) {
    return state.myApps;
  },
  unusedApps(state) {
    return state.unusedApps;
  },
};
