<template>
  <div class="d-flex flex-column">
    <!-- DEFAULT DRAG GROUP -->
    <v-card
      tile
      elevation="0"
    >
      <v-card-title>My Apps</v-card-title>
      <draggable
        v-model="myApps"
        v-bind="dragOptions"
        :move="onMove"
        @start="setDraggingTrueDelayed()"
        @end="isDragging=false"
      >
        <transition-group
          ref="panels"
          v-resize="getWindowWidth"
          type="transition"
          class="d-flex flex-wrap"
        >
          <HomeAppLink
            v-for="link in myApps"
            :key="`${link.name}_${link.order}`"
            :size="size"
            v-bind="link"
            :dragging="isDragging"
          />
        </transition-group>
      </draggable>
    </v-card>
    <v-card
      tile
      elevation="0"
    >
      <v-card-title>
        Available Apps
      </v-card-title>
      <draggable
        v-model="unusedApps"
        v-bind="dragOptions"
        :move="onMove"
        @start="setDraggingTrueDelayed()"
        @end="isDragging=false"
      >
        <transition-group
          type="transition"
          class="d-flex flex-wrap"
        >
          <HomeAppLink
            v-for="link in unusedApps"
            :key="`${link.name }_${ link.order}`"
            :size="size"
            v-bind="link"
            :dragging="isDragging"
          />
        </transition-group>
      </draggable>
    </v-card>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import HomeAppLink from './HomeAppLink.vue';

export default {
  name: 'HomeAppDefault',
  components: {
    HomeAppLink,
    draggable,
  },
  props: {
    nativeApps: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      editable: true,
      isDragging: false,
      delayedDragging: false,
      size: 0,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: !this.editable,
        ghostClass: 'ghost',
        // delayOnTouchOnly: true,
        // delay: 500,
      };
    },
    myApps: {
      get() {
        return this.$store.getters.myApps;
      },
      set(value) {
        this.$store.commit('setMyApps', value);
      },
    },
    unusedApps: {
      get() {
        return this.$store.getters.unusedApps;
      },
      set(value) {
        this.$store.commit('setUnusedApps', value);
      },
    },
  },
  watch: {
    // isDragging(newValue) {
    //   if (newValue) {
    //     this.delayedDragging = true;
    //   }
    //   this.$nextTick(() => {
    //     this.delayedDragging = false;
    //   });
    // },
    size() {
      return this.$refs.panels.$el.clientWidth;
    },
    nativeApps() {
      if (this.myApps.length < 1) {
        this.init();
      }
      this.loading = false;
    },
  },
  methods: {
    setDraggingTrueDelayed() {
      setTimeout(() => {
        this.isDragging = true;
      }, 300);
    },
    init() {
      this.loading = true;
      if (this.$store.getters.online) {
        this.myApps = this.nativeApps;
        this.$store
          .dispatch('setUnusedApps')
          .then(this.loading = false);
      }
      this.loading = false;
    },
    getWindowWidth() {
      this.size = this.$refs.panels.$el.clientWidth;
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;

      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
  },
};
</script>
