<template>
  <v-card
    id="edit-defaults-dialog"
    flat
  >
    <v-card-title class="justify-center">
      <h3 class="headline">
        Edit Defaults
      </h3>
    </v-card-title>
    <v-form>
      <v-autocomplete
        id="defaults-job_num-input"
        v-model="defaults.job_num"
        menu-props="auto"
        :items="jobnumArray"
        label="Job #"
      />
      <v-autocomplete
        id="defaults-cstcde-input"
        v-model="defaults.cstcde"
        menu-props="auto"
        :items="cstcdeArray"
        label="Cost Code"
      />
      <v-text-field
        v-model="defaults.description"
        attach
        counter="160"
        label="Description"
      />

      <v-text-field
        v-model.number="defaults.total"
        attach
        type="number"
        label="Hours Worked"
        :error-messages="totalErrors"
        min="0"
        @input="defaults.stop = null; defaults.start = null"
      />
      <v-row>
        <v-col cols="6">
          <v-text-field
            :value="toTwelveHr(defaults.start)"
            label="Start"
            readonly
            style="font-size: .9rem;"
            @click="timing = {dialog: true, value: defaults.start, max: '24:00', min: null, editing: 'start'}"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            :value="toTwelveHr(defaults.stop)"
            label="Stop"
            readonly
            style="font-size: .9rem;"
            @click="timing = {dialog: true, value: defaults.stop, max: null, min: defaults.start, editing: 'stop'}"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-btn
      label="Clear"
      color="warning"
      class="mr-2"
      @click="clearDefaults"
    >
      Clear
    </v-btn>
    <v-btn
      id="save-defaults-btn"
      :disabled="saveIsDisabled"
      color="success"
      label="Save"
      class="justify-center ma-2"
      :loading="saving"
      @click="requestDefaultsChange"
    >
      Save
    </v-btn>
    <!-- Start Stop time picker dialog -->
    <v-dialog
      v-model="timing.dialog"
      width="auto"
    >
      <v-time-picker
        v-if="timing.dialog"
        v-model="timing.value"
        :max="timing.max"
        :min="timing.min"
        :allowed-minutes="v => v % 15 === 0"
        @click:minute="handleTiming()"
      >
      </v-time-picker>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from 'moment/src/moment';
import timeArrays from '../../../mixins/time-values.js';

export default {
  name: 'ModifyDefaults',
  mixins: [timeArrays],
  data() {
    return {
      saving: false,
      defaults: {
        emp_num: null,
        start: null,
        stop: null,
        cstcde: null,
        job_num: null,
        description: '',
        total: 0,
      },
      timing: {
        editing: null,
        dialog: false,
        max: null,
        min: null,
        value: null,
      },
      cstcdeArray: [],
      jobnumArray: [],
    };
  },
  computed: {
    totalErrors() {
      const errors = [];
      const total = this.defaults.total;
      if (total < 0) {
        errors.push('Can\'t be less than 0');
      }
      if ((total % 0.25)) {
        errors.push('Must be .25 increment');
      }
      if (total > 24) {
        errors.push("Can't have more than 24 hours");
      }
      return errors;
    },
    saveIsDisabled() {
      return (this.defaults.description && this.defaults.description.length > 160)
      || this.defaults.total > 24
      || this.defaults.total < 0
      || Boolean(this.defaults.total % 0.25);
    },
  },
  watch: {
    'defaults.job_num': function processCostCodesThing() {
      this.processCostCodes();
    },
  },
  created() {
    this.getJobsCostCodesAndDefaults();
  },
  methods: {
    clearDefaults() {
      this.defaults = {
        start: null,
        stop: null,
        cstcde: null,
        job_num: null,
        description: '',
        total: 0,
      };
    },

    handleTiming() {
      const obj = this.timing;
      const key = obj.editing;
      const val = moment(obj.value, 'HH:mm').format('HH:mm:ss');
      this.defaults[key] = val;
      this.timing = {
        dialog: false, value: null, max: null, min: null, editing: null,
      };
      this.arrangeStartStop();
    },

    toTwelveHr(time) {
      if (!time) {
        return null;
      }
      return moment(time, 'HH:mm:ss').format('hh:mm A');
    },

    async getJobsCostCodesAndDefaults() {
      const { defaults } = this.$store.state.weekly;
      const { jobs } = this.$store.state.shared;
      this.processCostCodes();
      this.processJobs(jobs);
      this.processDefaults(defaults);
    },
    async processCostCodes() {
      const cc = this.$store.state.shared.costcodes[this.defaults.job_num] || [];
      // eslint-disable-next-line no-shadow
      const costcodes = cc.map((cc) => {
        return { text: `${cc.costcode_num} - ${cc.description}`, value: cc.costcode_num };
      });
      if (this.defaults.job_num === null) {
        costcodes.push({ text: '305.000 - Supervision', value: '305.000' });
      }
      const sorted = costcodes.sort((a, b) => {
        return parseFloat(a.value) - parseFloat(b.value);
      });
      this.cstcdeArray = sorted;
      this.cstcdeArray.unshift({
        text: 'None',
        value: null,
      });
    },
    processJobs(jobs) {
      this.jobnumArray = jobs.map((job) => {
        return { text: `${job.job_num} - ${job.job_name}`, value: job.job_num };
      });
      this.jobnumArray.unshift({
        text: 'None',
        value: null,
      });
    },
    processDefaults(defaults) {
      this.defaults = defaults;
    },
    async requestDefaultsChange() {
      const userData = this.defaults;
      userData.emp_num = this.$store.getters.user.emp_num;
      this.saving = true;
      await this.$store.dispatch('saveDefaultsToCenters', userData);
      this.saving = false;
    },
    arrangeStartStop() {
      if (!this.defaults.stop || this.defaults.start >= this.defaults.stop) {
        this.defaults.stop = this.incrementTime(this.defaults.start);
      }
      this.defaults.total = this.startStopDifference(this.defaults);
    },
    startStopDifference(entry) {
      const startTime = moment.duration(entry.start).asHours();
      const stopTime = moment.duration(entry.stop).asHours();
      return stopTime - startTime;
    },
    incrementTime(time) {
      return moment(time, 'HH:mm:ss').add(15, 'minutes').format('HH:mm:ss');
    },
  },
};
</script>
<style>
form,
.v-dialog {
  text-align: center;
}
</style>
