<template>
  <v-row
    justify="center"
    no-gutters
  >
    <v-col cols="8">
      <v-autocomplete
        :id="`inspection-eqnum-input-${index}`"
        v-model="entry.eq_num"
        filled
        class="mr-2"
        attached
        :items="iftaEquipmentList"
        :allow-overflow="false"
        :disabled="submitted"
        menu-props="auto"
        :error-messages="equipmentErrors"
        label="Equip #"
      >
        <template #selection="data">
          {{ data.item.value }}
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="4">
      <v-text-field
        :id="`inspection-total-input-${index}`"
        :value="entry.total"
        filled
        label="Hours"
        :disabled="submitted"
        readonly
      />
    </v-col>
    <v-col cols="6">
      <v-text-field
        :value="toTwelveHr(entry.start)"
        label="Start"
        readonly
        filled
        class="mr-1"
        style="font-size: .9rem;"
        :error-messages="startStopErrors"
        @click="timing = {dialog: true, value: entry.start, max: '24:00', min: null, editing: 'start'}"
      >
      </v-text-field>
    </v-col>
    <v-col cols="6">
      <v-text-field
        :value="toTwelveHr(entry.stop)"
        label="Stop"
        disabled
        filled
        class="ml-1"
        style="font-size: .9rem;"
        :error-messages="startStopErrors"
      >
      </v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field
        :id="`inspection-description-display-${index}`"
        :value="entry.description"
        dense
        outlined
        hide-details="auto"
        label="Description"
        readonly
        :disabled="submitted"
      />
    </v-col>
    <!-- Start Stop time picker dialog -->
    <v-dialog
      v-model="timing.dialog"
      width="auto"
    >
      <v-time-picker
        v-if="timing.dialog"
        v-model="timing.value"
        :max="timing.max"
        :min="timing.min"
        :allowed-minutes="v => v % 15 === 0"
        @click:minute="handleTiming()"
      >
      </v-time-picker>
    </v-dialog>
  </v-row>
</template>
<script>
/* eslint-disable vue/require-prop-types */

export default {
  name: 'InspectionPg',
  props: [
    'index',
    'mainEntry',
    'iftaEquipmentList',
    'toTwelveHr',
    'toProperTime',
    'handleHours',
    'otherUsedTime',
    'overlapping',
    'submitted',
    'incrementTime',
  ],
  data() {
    return {
      entry: {
        total: '', id: '', start: '', stop: '', description: '', eq_num: '',
      },
      timeout: null,
      allowChangeEmit: false,
      timing: {
        dialog: false,
        max: null,
        min: null,
        value: null,
      },
    };
  },

  computed: {
    // Returns true if current record is overlapping something
    overLappingRecord() {
      return this.overlapping(this.entry, this.otherUsedTime);
    },
    startStopErrors() {
      const errors = [];
      if (this.overLappingRecord) {
        errors.push('Overlapping');
      }
      return errors;
    },
    equipmentErrors() {
      const errors = [];
      if (this.empty('eq_num')) {
        errors.push('Required');
      }
      return errors;
    },
  },
  watch: {
    entry: {
      handler(val) {
        if (!this.allowChangeEmit) {
          return;
        }
        // LEAVE THE JSON PARSE, ITS REQUIRED
        this.$emit('entryChanged', JSON.parse(JSON.stringify(val)));
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.entry = JSON.parse(JSON.stringify(this.mainEntry));
    this.$nextTick(() => {
      this.allowChangeEmit = true;
    });
  },
  methods: {
    handleTiming() {
      const val = this.toProperTime(this.timing.value);
      this.entry.start = val;
      this.timing = {
        dialog: false, value: null, max: null, min: null, editing: null,
      };
      this.changeStopTime(this.entry);
    },
    changeStopTime(entry) {
      this.entry.stop = this.incrementTime(entry.start);
      this.handleHours(entry);
    },
    /////////////////////////
    // VALIDATION HELPERS
    ////////////////////////
    empty(key) {
      return !this.entry[key];
    },
  },
};
</script>
