<template>
  <v-card id="quick-record-modal">
    <!-- Close Button -->
    <v-btn
      id="close-quick-record-modal"
      absolute
      fab
      depressed
      top
      right
      x-small
      style="right: -5px"
      color="error"
      @click="complexClose"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <v-btn
      id="quick-record-instructions-btn"
      x-small
      icon
      class="d-flex justify-left ml-1"
      dense
      @click="helpDialog = true"
    >
      <v-icon class="mt-1">
        mdi-help-circle
      </v-icon>
    </v-btn>
    <!-- Clear Record Button -->

    <v-card-title class="d-flex justify-center pt-0">
      <v-btn
        id="clear-quick-record-btn"
        class="d-flex"
        color="red"
        icon
        @click="confirmClearAll"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
      QUICK RECORD
    </v-card-title>
    <v-card-text>
      <!-- FORM FOR TIME ENTRY -->
      <v-form v-if="entry">
        <v-row
          no-gutters
          class="mt-4"
        >
          <!-- JOB INPUT -->
          <v-col
            cols="12"
            mt-1
          >
          </v-col>
          <v-row dense>
            <!-- START STOP  -->
            <v-col cols="12">
              <v-row no-gutters>
                <v-col cols="6">
                  <v-select
                    id="quick-record-start"
                    v-model="entry.start"
                    class="mr-1"
                    menu-props="auto"
                    label="Start"
                    :items="newTimeArray.slice(0, -1)"
                  >
                    <template #selection="props">
                      <div
                        id="qr-start-display"
                        style="font-size: .9rem;"
                      >
                        {{ props.item.text }}
                      </div>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    id="quick-record-stop"
                    v-model="entry.stop"
                    class="ml-1"
                    menu-props="auto"
                    label="Stop"
                    :disabled="!entry.start"
                    :items="newTimeArray"
                  >
                    <template #selection="props">
                      <div
                        id="qr-stop-display"
                        style="font-size: .9rem;"
                      >
                        {{ props.item.text }}
                      </div>
                    </template>
                  </v-select>
                </v-col>
                <v-btn
                  v-if="!entry.stop"
                  id="start-quick-record-btn"
                  class="d-flex justify-center"
                  color="blue"
                  width="110px"
                  outlined
                  @click="closeQR"
                >
                  Start
                  <v-icon>
                    mdi-forward
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="entry.stop"
                  id="stop-quick-record-btn"
                  width="100px"
                  color="success"
                  outlined
                  @click="openRecord"
                >
                  Stop
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-row>
      </v-form>
    </v-card-text>
    <!-- Instructions for users -->
    <v-dialog
      v-model="helpDialog"
      width="90%"
      max-width="500"
    >
      <v-card id="instructions-modal">
        <v-card-title class="grey headline lighten-2">
          Instructions
        </v-card-title>

        <v-card-text class="px-0">
          1. Click the blue circle, this will fill the start time with the current time
          (Note: You have already completed this step)
        </v-card-text>
        <v-card-text class="px-0">
          2. Click start, and go to work
        </v-card-text>
        <v-card-text class="px-0">
          3. Once finished with work, click the circle again, this will fill the stop time
        </v-card-text>
        <v-card-text class="px-0">
          4. Click stop to finish your record with any equipment or job numbers
        </v-card-text>
        <span class="caption">
          Note: This is for same day entries only, nothing past midnight
        </span>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="close-intructions-btn"
            color="primary"
            text
            @click="helpDialog = false"
          >
            Okay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Alertbox ref="alertbox" />
  </v-card>
</template>

<script>
import moment from 'moment/src/moment';
import timeArrays from '../../../mixins/time-values.js';
import Alertbox from '../../alert/alert.vue';
/* eslint-disable vue/no-undef-properties */
export default {
  name: 'QuickRecord',
  components: { Alertbox },
  mixins: [timeArrays],
  data: () => {
    return {
      entry: { date: '', total: 0 },
      wrongDay: false,
      helpDialog: false,
    };
  },
  watch: {
    /* eslint-disable func-names */
    // eslint-disable-next-line vue/no-undef-properties
    'entry.start': function () {
      if (this.entry.start < this.roundTime()) {
        this.entry.stop = this.roundTime();
      }
    },
    //This detects if you have a record from yesterday you never made, and fires a clear alert
    // eslint-disable-next-line vue/no-undef-properties
    'entry.stop': function () {
      if (this.entry.stop !== '' && this.entry.stop < this.entry.start) {
        this.confirmClearAll('wrongTime');
      }
    },
  },
  async created() {
    this.$nextTick(() => {
      this.entry = { ...this.$store.state.weekly.quickRecord };
      const todaysDate = moment().format('YYYY-MM-DD');
      if (todaysDate > this.entry.date) {
        this.confirmClearAll('badDate');
      }
    });
  },
  methods: {
    roundTime() {
      const time = moment();
      const roundInt = 15;
      const remainder = time.minute() % roundInt;
      return time.subtract(remainder, 'minutes').add(remainder > roundInt / 2 ? roundInt : 0, 'minutes').format('HH:mm:00');
    },
    complexClose() {
      if (!this.entry.stop) {
        this.clearAll();
      } else {
        this.closeQR();
      }
    },
    // Takes in a booleon if the wrong day error is required
    async confirmClearAll(command) {
      let res = false;
      if (command === 'wrongTime') {
        res = await this.userAlert('Stop time can\'t be before start time, do you wish to clear?', true);
        this.wrongDay = true;
      } else if (command === 'badDate') {
        res = await this.userAlert('You have a record from a previous date, do you wish to clear?', true);
        this.wrongDay = true;
      } else {
        res = await this.userAlert('Are you sure you wish to clear?', true);
      }
      if (res) {
        this.clearAll();
      }
    },
    clearAll() {
      if (this.wrongDay) {
        this.entry.start = this.roundTime();
        this.entry.stop = '';
        this.wrongDay = false;
      } else {
        this.entry.start = '';
        this.entry.stop = '';
        this.closeQR();
      }
    },
    openRecord() {
      this.entry.total = this.startStopDifference(this.entry);
      const date = moment().format('YYYY-MM-DD');
      this.$store.commit('setQuickRecord', {});
      this.$store.commit('setTimecardEntry', this.entry);
      this.$store.commit('setSelectedDay', date);
      this.$emit('updateTimeForm', true);
    },
    closeQR() {
      this.$emit('updateQuickRecord', false);
      this.$store.commit('setQuickRecord', this.entry);
    },
    startStopDifference(entry) {
      const startTime = moment.duration(entry.start).asHours();
      const stopTime = moment.duration(entry.stop).asHours();
      return stopTime - startTime;
    },
    async userAlert(message, confirm) {
      return this.$refs.alertbox.show(message, confirm);
    },
  },
};
</script>
<style>
  .theme--dark.v-subheader {
    color: #ef9f0ef0;
  }
</style>
