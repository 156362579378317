<template>
  <v-row no-gutters>
    <!-- JOB INPUT -->
    <v-col
      cols="5"
    >
      <v-autocomplete
        :id="`duty-jobnum-input-${index}`"
        v-model="entry.job_num"
        :items="jobs"
        filled
        :allow-overflow="false"
        :disabled="submitted"
        menu-props="auto"
        clearable
        label="Job #"
        @input="handleJobnum()"
      >
        <template #selection="data">
          {{ data.item.value }}
        </template>
      </v-autocomplete>
    </v-col>

    <!-- COST CODE INPUT -->
    <v-col
      v-if="entry.cstcde !== '1015.000'"
      cols="7"
    >
      <v-autocomplete
        :id="`duty-cstcde-input-${index}`"
        v-model="entry.cstcde"
        menu-props="auto"
        class="ml-1"
        filled
        :items="allCostCodes"
        :allow-overflow="false"
        :error-messages="costcodeErrors"
        :disabled="submitted"
        label="Cost Code"
        clearable
        @change="updateEquipment"
      >
        <template #selection="data">
          {{ data.item.value }}
        </template>
        <template #no-data>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                style="cursor: pointer"
                @click="$emit('openInfoDialog')"
              >
                Can't find a cost code? Click here.
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-col>

    <v-col cols="6">
      <v-text-field
        :value="toTwelveHr(entry.start)"
        label="Start"
        readonly
        filled
        class="mr-1"
        style="font-size: .9rem;"
        :error-messages="startStopErrors"
        @click="timing = {dialog: true, value: entry.start, max: '24:00', min: null, editing: 'start'}"
      >
      </v-text-field>
    </v-col>
    <v-col cols="6">
      <v-text-field
        :value="toTwelveHr(entry.stop)"
        label="Stop"
        readonly
        filled
        class="ml-1"
        style="font-size: .9rem;"
        :error-messages="startStopErrors"
        @click="timing = {dialog: true, value: entry.stop, max: null, min: entry.start, editing: 'stop'}"
      >
      </v-text-field>
    </v-col>
    <!-- EQUIPMENT INPUT -->
    <v-col
      cols="6"
    >
      <v-autocomplete
        :id="`duty-eqnum-input-${index}`"
        v-model="entry.eq_num"
        :items="equipmentList"
        filled
        clearable
        :allow-overflow="false"
        label="Equip #"
        :error-messages="equipmentErrors"
        :disabled="submitted || (entry.cstcde === '1003.000')"
        hide-details="auto"
        @change="blurInputs()"
      >
        <template #selection="data">
          {{ data.item.value }}
        </template>
      </v-autocomplete>
    </v-col>

    <v-col cols="6">
      <v-text-field
        :id="`duty-eqphrs-input-${index}`"
        v-model="entry.eqphrs"
        step="0.25"
        class="ml-1"
        suffix="hrs"
        filled
        label="Eq Hrs"
        :disabled="!entry.eq_num || $store.state.shared.repairCodes.includes(entry.cstcde) || submitted
          || (entry.cstcde === '1003.000')"
        :error-messages="eqphrsErrors"
      />
    </v-col>
    <v-col cols="12">
      <v-textarea
        :id="`duty-description-input-${index}`"
        v-model="entry.description"
        label="Description"
        rows="1"
        auto-grow
        outlined
        :counter="160"
        maxlength="160"
        :disabled="submitted"
        :error-messages="descriptionErrors"
        @focus="scrollDownABit()"
      />
    </v-col>
    <v-col
      cols="3"
    >
      <v-text-field
        :id="`duty-lunch-input-${index}`"
        v-model="entry.lunch"
        min="0"
        step="0.25"
        dense
        class="mr-2"
        hide-details="auto"
        :disabled="submitted"
        :error-messages="lunchErrors"
        @input="handleHours(entry)"
      >
        <template #label>
          <div style="font-weight: 600;font-size:12px">
            lunch
          </div>
        </template>
      </v-text-field>
    </v-col>
    <!-- HOURS -->
    <v-col
      cols="9"
      class="d-flex justify-end"
    >
      <v-text-field
        :id="`duty-total-input-${index}`"
        :value="entry.total"
        class="mr-2"
        dense
        hide-details="auto"
        label="Total"
        :disabled="submitted"
        readonly
        :error-messages="totalErrors"
      />
      <v-checkbox
        :id="`duty-offsite-checkbox-${index}`"
        v-model="entry.offsite"
        true-value="1"
        hide-details
        :false-value="null"
        label="Offsite"
        class="mt-0 offsite"
        :disabled="submitted || disabledOffsite()"
      ></v-checkbox>
    </v-col>
    <!-- Start Stop time picker dialog -->
    <v-dialog
      v-model="timing.dialog"
      width="auto"
    >
      <v-time-picker
        v-if="timing.dialog"
        v-model="timing.value"
        :max="timing.max"
        :min="timing.min"
        :allowed-minutes="v => v % 15 === 0"
        @click:minute="handleTiming()"
      >
      </v-time-picker>
    </v-dialog>
  </v-row>
</template>
<script>
/* eslint-disable vue/require-prop-types */

export default {
  name: 'DutyEntry',
  props: [
    'mainEntry',
    'index',
    'jobs',
    'equipment',
    'incrementTime',
    'handleHours',
    'toTwelveHr',
    'toProperTime',
    'otherUsedTime',
    'overlapping',
    'submitted',
    'equipmentInfo',
  ],
  data() {
    return {
      entry: {
        id: null,
        total: null,
        start: null,
        stop: null,
        eq_num: null,
        eqphrs: null,
        job_num: null,
        cstcde: null,
        offsite: null,
        lunch: null,
        description: null,
      },
      timing: {
        editing: null,
        dialog: false,
        max: null,
        min: null,
        value: null,
      },
      allCostCodes: [],
      timeout: null,
      allowChangeEmit: false,
    };
  },
  computed: {
    equipmentList() {
      const jobEquipment = this.$store.state.shared.jobEquipment[this.entry.job_num] || [];
      const job = jobEquipment
        .map((eq) => {
          return { text: eq.description, value: eq.eq_num };
        });
      const insertIndex = 1;
      return [
        ...this.equipment.slice(0, insertIndex),
        { header: 'Job Equipment' },
        ...job,
        { divider: true },
        ...this.equipment.slice(insertIndex),
      ];
    },
    // Returns true if current record is overlapping something
    overLappingRecord() {
      return this.overlapping(this.entry, this.otherUsedTime);
    },

    ///////////////
    // VALIDATION
    ///////////////
    descriptionErrors() {
      const errors = [];
      if (this.empty('description')) {
        errors.push('Required');
      }
      return errors;
    },
    costcodeErrors() {
      const errors = [];
      if (this.empty('cstcde')) {
        errors.push('Required');
      }
      return errors;
    },
    equipmentErrors() {
      const errors = [];
      if (this.empty('eq_num') && (this.entry.cstcde !== '1003.000')
      && this.$store.state.shared.repairCodes.includes(this.entry.cstcde)) {
        errors.push('Required');
      }
      return errors;
    },
    startStopErrors() {
      const errors = [];
      if (this.overLappingRecord) {
        errors.push('Overlapping');
      }
      return errors;
    },
    lunchErrors() {
      const errors = [];
      if (!this.divisibleByQuarter(this.entry.lunch)) {
        errors.push('has to be .25 increments');
      }
      if (parseFloat(this.entry.lunch) < 0) {
        errors.push('Must be positive');
      }
      return errors;
    },
    totalErrors() {
      const errors = [];
      if (parseFloat(this.entry.total) < 0.25) {
        errors.push('Must be at least 0.25');
      }
      return errors;
    },
    eqphrsErrors() {
      const errors = [];
      const hrs = parseFloat(this.entry.eqphrs);
      if (!this.entry.eq_num || this.$store.state.shared.repairCodes.includes(this.entry.cstcde)) {
        return errors;
      }
      if (hrs < 0) {
        errors.push('Must be positive');
      }
      if (hrs > parseFloat(this.entry.total)) {
        errors.push('Must be less than total');
      }
      if (!hrs) {
        errors.push('Required if equipment selected');
      }
      if (!this.divisibleByQuarter(hrs)) {
        errors.push('Must be .25 increment');
      }
      return errors;
    },
  },
  watch: {
    entry: {
      handler(val) {
        if (!this.allowChangeEmit) {
          return;
        }
        this.$emit('entryChanged', JSON.parse(JSON.stringify(val)));
      },
      deep: true,
      immediate: true,
    },
    'entry.eq_num': function clearEqHrs() {
      if (!this.entry.eq_num) {
        this.entry.eq_num = null;
        this.entry.eqphrs = null;
      }
    },
    'entry.cstcde': function clearEqHrsForCertainCostCodes() {
      if (this.$store.state.shared.repairCodes.includes(this.entry.cstcde)) {
        this.entry.eqphrs = null;
      }
      if (this.entry.cstcde === '1003.000') {
        this.entry.eq_num = null;
        this.entry.eqphrs = null;
      }
    },
    'entry.total': function setEqHrsEqualToTotal() {
      if (this.entry.eq_num && !this.$store.state.shared.repairCodes.includes(this.entry.cstcde)) {
        this.entry.eqphrs = this.entry.total;
      }
    },
  },
  mounted() {
    this.entry = JSON.parse(JSON.stringify(this.mainEntry));
    this.filtercostcodes(this.entry);
    this.$nextTick(() => {
      this.allowChangeEmit = true;
    });
  },
  methods: {
    handleTiming() {
      const obj = this.timing;
      const key = obj.editing;
      const val = this.toProperTime(obj.value);
      this.entry[key] = val;
      this.timing = {
        dialog: false, value: null, max: null, min: null, editing: null,
      };
      if (key === 'stop') {
        this.handleHours(this.entry);
      } else {
        this.changeStopTime(this.entry);
      }
    },
    changeStopTime(entry) {
      if (this.entry.start >= this.entry.stop) {
        this.entry.stop = this.incrementTime(entry.start);
      }
      this.handleHours(entry);
    },
    updateEquipment(e) {
      this.blurInputs();
      if (e !== '1003.00' && this.equipmentInfo.record && this.equipmentInfo.record.eq_num) {
        this.entry.eq_num = this.equipmentInfo.record.eq_num;
      }
    },
    disabledOffsite() {
      const isNotPublic = this.$store.state.weekly.publicJobs.filter((e) => {
        return e.job_num === this.entry.job_num;
      }).length === 0;
      if (isNotPublic) {
        this.entry.offsite = null; // side effect, clears the offsite checkbox
      }
      return isNotPublic;
    },
    handleJobnum() {
      this.blurInputs();
      this.entry.cstcde = null;
      this.filtercostcodes(this.entry);
    },
    blurInputs() {
      document.activeElement.blur();
    },
    scrollDownABit() {
      setTimeout(() => {
        const el = document.getElementById('total-and-drive-times-card');
        el.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    },
    filtercostcodes(item) {
      const jobnum = item.job_num || null;
      if (!this.$store.state.shared.costcodes || !this.$store.state.shared.costcodes[jobnum]) {
        this.allCostCodes = [{ text: 'None', value: null }];
        return;
      }
      this.allCostCodes = this.$store.state.shared.costcodes[jobnum]
        .filter((costcode) => {
          return !['0.000', '140.000', '160.000', '1015.000'].includes(costcode.costcode_num);
        })
        .sort((a, b) => {
          return Number(a.costcode_num) - Number(b.costcode_num);
        })
        .map((cc) => {
          return { text: `${cc.costcode_num} ${cc.description}`, value: cc.costcode_num };
        });
    },
    /////////////////////////
    // VALIDATION HELPERS
    ////////////////////////
    empty(key) {
      return !this.entry[key];
    },
    divisibleByQuarter(value) {
      return (value % 0.25 === 0);
    },
  },
};
</script>
