<template>
  <v-main>
    <v-container>
      <v-row
        class="d-flex flex-column justify-center pa-5"
        style="min-height:85vh;max-width:90%;margin:auto;width:300px;"
      >
        <v-card>
          <v-card-text>
            <v-form
              lazy-validation
              @submit.prevent="submitLogin"
            >
              <a
                rel="home"
              >
                <v-row
                  justify="center"
                  class="ma-0 mb-2"
                >
                  <v-img
                    contain
                    class="custom-logo"
                    src="img/logo-600x363.png"
                    lazy-src="img/logo-124x80.png"
                    alt="Rotschy Inc"
                    itemprop="logo"
                    max-width="124"
                    max-height="80"
                  />
                </v-row>
              </a>
              <v-text-field
                id="un"
                v-model="username"
                autocomplete="username"
                label="Username"
                required
                @input="username = username.toLowerCase()"
              />
              <v-text-field
                id="pw"
                v-model="password"
                autocomplete="current-password"
                label="Password"
                type="password"
              />
              <v-checkbox
                id="chkbx"
                v-model="rememberMe"
                label="Remember Username"
              />
              <v-checkbox
                v-if="isAllowedToSaveBiometrics"
                id="chkbx2"
                v-model="saveCredentials"
                :label="`Save credentials with ${availableBiometricType}`"
              />
              <v-row>
                <v-col cols="12">
                  <v-btn
                    id="smt"
                    type="submit"
                    label="Submit"
                    :loading="processing"
                  >
                    Login
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <span
          id="version"
          class="font-weight-thin"
        >v{{ currentVersion }}</span>
      </v-row>
    </v-container>
    <v-footer
      app
      fixed
    >
      <a
        class="pr-2 text-left"
        href="mailto: howard.redinger@rotschyinc.com?subject=App Help"
      >Need help?</a>
      <v-spacer></v-spacer>
      <v-menu
        top
        offset-y
      >
        <v-list>
          <v-list-item
            v-if="isAllowedToSignInWithBiometrics"
            @click="loginWithBiometrics"
          >
            <v-list-item-title>Log in with {{ availableBiometricType }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isAllowedToSignInWithBiometrics"
            @click="handleClickDeleteSavedCredentials"
          >
            <v-list-item-title>Delete saved credentials</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="serverDialog = true"
          >
            <v-list-item-title>Change server</v-list-item-title>
          </v-list-item>
        </v-list>
        <template #activator="{ on }">
          <v-btn
            large
            icon
            v-on="on"
          >
            <v-icon>mdi-account-cog</v-icon>
          </v-btn>
        </template>
      </v-menu>
    </v-footer>
    <v-dialog
      v-model="serverDialog"
      max-width="350px"
      width="90%"
      persistent
    >
      <v-card id="server">
        <v-card-title>
          Server URL
        </v-card-title>
        <v-card-text id="serverInput">
          <v-combobox
            v-model="serverURL"
            :items="serverList"
            menu-props="auto"
            @change="handleUpdateServerList"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="serverSave"
            color="success"
            @click="handleSaveServer"
          >
            Save
          </v-btn>
          <v-btn
            id="serverClose"
            color="red"
            dark
            right
            @click="serverDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Alertbox ref="alertbox" />
    <v-snackbar
      v-model="snackbar"
      color="primary"
    >
      Your saved credentials have been deleted
    </v-snackbar>
  </v-main>
</template>
<script>
import Alertbox from './alert/alert.vue';

export default {
  name: 'LoginPg',
  components: {
    Alertbox,
  },
  data() {
    return {
      rememberMe: false,
      username: '',
      password: '',
      serverDialog: false,
      serverURL: 'https://rinc.us/',
      processing: false,
      serverList: [],
      availableBiometricType: 'none',
      saveCredentials: false,
      secretObj: {},
      snackbar: false,
    };
  },
  computed: {
    currentVersion() {
      return this.$store.getters.currentVersion;
    },
    isAllowedToSaveBiometrics() {
      return (this.availableBiometricType !== 'none' && this.savedBiometricType === 'none');
    },
    isAllowedToSignInWithBiometrics() {
      const savedUsername = localStorage.getItem(`${this.serverStorageKey}:username`);
      return this.availableBiometricType !== 'none'
        && this.savedBiometricType !== 'none'
        && this.savedBiometricType === this.availableBiometricType
        && savedUsername;
    },
    serverStorageKey() {
      return `login:${this.serverURL}`;
    },
    savedBiometricType() {
      return localStorage.getItem(`${this.serverStorageKey}:biometricType`) || 'none';
    },
  },
  async created() {
    const isFromLogout = await localStorage.getItem('logout');
    this.setLogoutFalse();

    // Clear the snackbar state
    this.$store.commit('setSnackbar', {
      show: false,
      time: -1,
      message: '',
      color: 'primary',
    });

    // Set current version, DO NOT DELETE
    this.$store.commit('setCurrentVersion');

    // Set previously used servers
    const list = JSON.parse(localStorage.getItem('serverList')) || [];
    if (list.length > 0) {
      this.serverList = list;
    }

    // Set previously used server
    const server = localStorage.getItem('serverURL') || 'https://rinc.us/';
    if (server) {
      this.serverURL = server;
    }

    // Set username if it was saved
    const username = localStorage.getItem(`${this.serverStorageKey}:username`) || '';
    if (username) {
      this.username = username;
      this.rememberMe = true;
    }

    // Check if biometric authentication is available
    await this.getBiometricAvailability();

    // Automatically route to previous location if logged in
    if (this.$store.getters.user.username) {
      this.routeToNextPage();
    } else if (this.savedBiometricType !== 'none'
      && isFromLogout !== 'true'
      && this.serverURL) {
      // Check if user has saved credentials and they have not been
      // redirected from another screen
      // if so, log them in
      await this.loginWithBiometrics();
    }

    // Alert user if they are on ios and in browser, that app will not work unless cross site scripting is turned off
    this.alertUserIfOnIosAndBrowser();
  },

  mounted() {
    this.setLogoutFalse();
  },

  methods: {
    setLogoutFalse() {
      localStorage.setItem('logout', 'false');
    },

    async handleClickDeleteSavedCredentials() {
      const res = await this.userAlert('Are you sure you want to delete saved credentials?', true);
      if (res) {
        this.deleteSavedCredentials();
      }
    },

    deleteSavedCredentials() {
      this.serverList.forEach((server) => {
        localStorage.removeItem(`login:${server}:biometricType`);
      });
      localStorage.removeItem(`${this.serverStorageKey}:biometricType`);
      localStorage.removeItem(`${this.serverStorageKey}:username`);
      this.username = '';
      this.password = '';
      this.saveCredentials = false;
      this.rememberMe = false;
      this.snackbar = true;
      this.availableBiometricType = 'none';
    },

    getBiometricAvailability() {
      Fingerprint.isAvailable(
        (result) => {
          switch (result) {
            case 'face':
              this.availableBiometricType = 'FaceID';
              break;
            case 'finger':
              this.availableBiometricType = 'Fingerprint';
              break;
            case 'biometric':
              this.availableBiometricType = 'Biometrics';
              break;
            default:
              this.availableBiometricType = 'none';
          }
        },
        (error) => {
          this.availableBiometricType = 'none';
          console.log('Console Log', error); // eslint-disable-line no-console
        },
        {
          allowBackup: true,
          requireStrongBiometrics: true,
        },
      );
    },

    async submitLogin() {
      this.processing = true;
      if (this.rememberMe || this.saveCredentials) {
        localStorage.setItem(`${this.serverStorageKey}:username`, this.username);
      }
      if (this.serverURL) {
        const attemptLogin = await this.attemptLogin();
        if (attemptLogin && attemptLogin.login === 'success') {
          this.$store.dispatch('setServer', this.serverURL);
          // Save biometric credentials if user has selected to do so
          if (this.saveCredentials) {
            await this.storeCredentialsWithBiometrics();
          } else {
            this.routeToNextPage();
          }
        } else if (attemptLogin && attemptLogin.login === 'failure') {
          this.userAlert(attemptLogin.message);
        } else {
          this.userAlert(`Error logging in. Please try again. ${attemptLogin.login}`);
        }
      } else {
        this.userAlert('Enter a Server before submitting login info');
      }
    },

    async loginWithBiometrics() {
      await Fingerprint.loadBiometricSecret(
        {
          title: 'Sign in with Biometrics',
          subtitle: '',
          description: 'Authenticate',
          invalidateOnEnrollment: true,
        },
        async (secret) => {
          this.secretObj = JSON.parse(secret);
          this.username = localStorage.getItem(`${this.serverStorageKey}:username`) || '';
          this.password = this.secretObj[this.serverURL] || '';

          if (this.password === '') {
            this.userAlert('No saved credentials found');
            return;
          }

          await this.submitLogin();
        },
        (error) => {
          console.log('Console Log', error); // eslint-disable-line no-console
          this.userAlert('Authentication failed. Please log in normally.');
        },
      );
    },

    async storeCredentialsWithBiometrics() {
      if (this.serverList.some((server) => {
        const hasSavedCredentials = localStorage.getItem(`login:${server}:biometricType`);
        return hasSavedCredentials && hasSavedCredentials !== 'none';
      })) {
        let success = false;
        await Fingerprint.loadBiometricSecret(
          {
            title: 'Load Credentials For Other Servers',
            subtitle: '',
            description: 'Authenticate',
          },
          async (secret) => {
            await this.registerNewSecret(secret);
            success = true;
          },
          (error) => {
            console.log('Console Log', error); // eslint-disable-line no-console
            this.userAlert('Error storing credentials');
          },
        );
        return success;
      }
      await this.registerNewSecret('{}', true);
      return true;
    },

    async registerNewSecret(oldSecret, authenticate = false) {
      this.secretObj = JSON.parse(oldSecret) || {};
      this.secretObj[this.serverURL] = this.password;
      await Fingerprint.registerBiometricSecret(
        {
          title: 'Save Credentials with Biometrics',
          subtitle: '',
          description: 'Authenticate',
          secret: JSON.stringify(this.secretObj),
          confirmationRequired: authenticate,
          invalidateOnEnrollment: true,
        },
        () => {
          localStorage.setItem(`${this.serverStorageKey}:biometricType`, this.availableBiometricType);
          localStorage.setItem(`${this.serverStorageKey}:username`, this.username);

          const userAgent = navigator.userAgent || navigator.vendor || window.opera;
          if (authenticate && /iPad|iPhone|iPod/.test(userAgent)) {
            Fingerprint.show({
              description: `Allow Rotschy Inc. to use ${this.availableBiometricType}`,
            }, () => {
              this.routeToNextPage();
            }, () => {
              this.userAlert('Authentication has been cancelled');
            });
          } else {
            this.routeToNextPage();
          }
        },
        (error) => {
          console.log('Console Log', error); // eslint-disable-line no-console
          this.userAlert('Error registering credentials');
        },
      );
    },

    async attemptLogin() {
      const version = this.currentVersion;
      return fetch(`${this.serverURL}login.php`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        mode: 'cors',
        // eslint-disable-next-line max-len
        body: `username=${this.username}&password=${encodeURIComponent(this.password)}&login-submit=LoginAPI&app-version=${version}`,
        credentials: 'include',
      })
        .then((response) => {
          return response.text();
        })
        .then((serverResponse) => {
          const { login, username } = serverResponse;
          if (login === 'success') {
            this.$store.commit('setUser', { username });
          }
          return JSON.parse(serverResponse);
        })
        .catch((error) => {
          const msg = String(error);
          if (msg.includes('NetworkError')) {
            return { login: 'You are offline' };
          }
          return { login: msg };
        });
    },

    handleUpdateServerList() {
      if (!this.serverList.includes(this.serverURL)) {
        this.serverList.push(this.serverURL);
      }
      localStorage.setItem('serverList', JSON.stringify(this.serverList));
    },

    async handleSaveServer() {
      localStorage.setItem('serverURL', this.serverURL);
      this.serverDialog = false;

      // Automatically run the biometric prompt again
      if (this.isAllowedToSignInWithBiometrics) {
        await this.loginWithBiometrics();
      }
    },

    routeToNextPage() {
      const appLinkLocation = localStorage.getItem('linkPath');
      const lastLocation = localStorage.getItem(`${this.username}:lastLocation`);
      const nextLocation = appLinkLocation || lastLocation || '/';

      if (appLinkLocation) {
        localStorage.removeItem('linkPath');
      }

      if (nextLocation === '/login' || this.$route.path === nextLocation) {
        this.$router.push('/home').catch(() => {});
      } else {
        this.$router.push(nextLocation).catch((e) => {
          console.log('Console Log (Routing error):', e); // eslint-disable-line no-console
        });
      }
    },

    alertUserIfOnIosAndBrowser() {
      // First check if browser
      if (device.model !== 'Safari') {
        return;
      }
      this.userAlert(`This website will not work unless cross-site tracking is allowed. 
To allow: Safari > Settings > Privacy > "Prevent cross-site tracking"`);
    },

    async userAlert(message, confirm = false) {
      this.processing = false;
      return this.$refs.alertbox.show(message, confirm);
    },
  },
};
</script>
<style>
form {
    text-align: center;
  }
body {
  padding-top: env(safe-are-inset-top);
}
footer {
  margin-bottom: env(safe-area-inset-bottom);
}
</style>
