// Takes in a cstk, and creates a nice string for a post request
function createPostString(cstk, task, userData) {
  const version = localStorage.getItem('cur_version');
  // eslint-disable-next-line max-len
  let paramsString = `center=${cstk.center}&section=${cstk.section}&tab=${cstk.tab}&kind=${cstk.kind}&dataType=json&app-version=${version}`;
  if (task) {
    paramsString = `${paramsString}&task=${task}`;
  }
  if (userData) {
    paramsString = `${paramsString}&userData=${encodeURIComponent(JSON.stringify(userData))}`;
  }
  return paramsString;
}

export default function fetchData(cstk, task, userData, server, timeout) {
  const postData = createPostString(cstk, task, userData);
  const controller = new AbortController();
  const { signal } = controller;
  const fetchPromise = fetch(`${server}controller.php`, {
    signal,
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8;',
    },
    body: postData,
  });
  const allowedTime = timeout || 30000;
  setTimeout(() => {
    return controller.abort();
  }, allowedTime);
  return fetchPromise.then((response) => {
    return response.json();
  })
    .catch((e) => {
      if (e.name === 'AbortError') {
        return {
          error: true,
          networkError: true,
          message: 'Network request timed out after 10 seconds',
        };
      }
      return {
        error: true,
        networkError: true,
        message: 'Error talking to server',
      };
    });
}
