import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"id":"weeklyCommentForm"}},[_c(VCardTitle,{staticClass:"justify-center"},[_c('h3',[_vm._v("Weekly Comment")])]),_c(VCardText,[_c(VContainer,[_c(VTextarea,{attrs:{"id":"comment-form-input","value":_vm.comment,"outlined":"","label":"Comment","maxlength":"80","counter":"80"},on:{"input":_vm.handleComment}})],1)],1),_c(VCardActions,{staticClass:"d-flex justify-end"},[_c(VBtn,{attrs:{"id":"cancelSave","loading":_vm.saving,"color":"warning"},on:{"click":function($event){return _vm.$emit('closeComment')}}},[_vm._v(" Close ")]),_c(VBtn,{attrs:{"id":"saveComment","loading":_vm.saving,"disabled":!_vm.newComment,"color":"success"},on:{"click":function($event){return _vm.saveComment()}}},[_vm._v(" Save ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }