/* eslint-disable no-param-reassign */
import weekly from '../api';

function organizeEntries(entries) {
  const { allDays } = entries.datesInfo;
  const allEntries = entries.timecardEntries === 'none' ? [] : entries.timecardEntries;
  const obj = {
    [allDays[0]]: {
      entries: [],
      totalHours: 0,
    },
    [allDays[1]]: {
      entries: [],
      totalHours: 0,
    },
    [allDays[2]]: {
      entries: [],
      totalHours: 0,
    },
    [allDays[3]]: {
      entries: [],
      totalHours: 0,
    },
    [allDays[4]]: {
      entries: [],
      totalHours: 0,
    },
    [allDays[5]]: {
      entries: [],
      totalHours: 0,
    },
    [allDays[6]]: {
      entries: [],
      totalHours: 0,
    },
  };
  allEntries.forEach((entry) => {
    const entryTotal = parseFloat(entry.total);
    obj[entry.date].totalHours += entryTotal;
    obj[entry.date].entries.push(entry);
  });
  return obj;
}

export default {
  async getCrusherJobs({ commit, getters }) {
    const response = await weekly.getCrusherJobs(getters.server);
    if (response.message === 'unauthenticated') {
      this.dispatch('logout');
    }
    if (response.status === 'success') {
      commit('setCrusherJobs', response.data);
      return true;
    }
    return false;
  },
  async getAllPublicJobs({ commit, getters }) {
    const response = await weekly.getAllPublicJobs(getters.server);
    if (response.message === 'unauthenticated') {
      this.dispatch('logout');
    }
    if (response.status === 'success') {
      commit('setPublicJobs', response.data);
      return true;
    }
    return false;
  },
  async getEmployeeSalariedStatus({ commit, getters }) {
    const response = await weekly.getEmployeeSalariedStatus(getters.user.emp_num, getters.server);
    if (response.message === 'unauthenticated') {
      this.dispatch('logout');
    }
    if (response.status === 'success' && response.message === 'success') {
      commit('setIsSalaried', response.data.salaried);
      return true;
    }
    if (response.status === 'success' && response.message === 'empty') {
      commit('setIsSalaried', false);
      return true;
    }
    return false;
  },
  async getEntries({ commit, dispatch, getters }) {
    const response = await weekly.getEntries(getters.user.emp_num, getters.server);
    if (response.some((res) => {
      return res.message === 'unauthenticated';
    })) {
      this.dispatch('logout');
    }
    if (response.every((res) => {
      return res.status === 'success';
    })) {
      const lastEntries = organizeEntries(response[0].data);
      const presentEntries = organizeEntries(response[1].data);
      const entries = {
        ...lastEntries,
        ...presentEntries,
      };
      commit('setEntries', entries);
      dispatch('setEmployeeStatus', response[0].data.employeeStatus);
      return true;
    }
    return false;
  },
  async getDefaults({ commit, getters }) {
    const response = await weekly.getDefaults(getters.user.emp_num, getters.server);
    if (response.message === 'unauthenticated') {
      this.dispatch('logout');
    }
    if (response.status === 'success' && response.message === 'success') {
      commit('setDefaults', response.data);
      return true;
    }
    return (response.message === 'empty');
  },
  async getLastUsedInfo({ commit, getters }) {
    const response = await weekly.getLastUsedData(getters.user.emp_num, getters.server);
    if (response.some((res) => {
      return res.unauthenticated;
    })) {
      this.dispatch('logout');
    }
    if (response.every((res) => {
      return (!res.error);
    })) {
      const lastObj = {
        jobs: response[0].response.data || [],
        equipment: response[1].response.data || [],
      };
      commit('setLastUsedInfo', lastObj);
      return true;
    }
    return false;
  },
  async getComments({ commit, getters }) {
    const response = await weekly.getComments(
      getters.user.emp_num,
      getters.server,
      getters.lastWeekDates,
      getters.presentWeekDates,
    );
    if (response.some((res) => {
      return res.unauthenticated;
    })) {
      this.dispatch('logout');
    }
    if (response.every((res) => {
      return !res.error;
    })) {
      const lastResponse = response[0].response.data ? response[0].response.data : {
        comment: '',
      };
      const presentResponse = response[1].response.data ? response[1].response.data : {
        comment: '',
      };
      const comment = {
        last: lastResponse,
        present: presentResponse,
      };
      commit('setComment', comment);
      return true;
    }
    return false;
  },
  async getInjury({ commit, getters }) {
    const empty = {
      injury_comment: null,
      id: null,
      notified_supervisor: '0',
    };
    const response = await weekly.getInjuries(
      getters.user.emp_num,
      getters.server,
      getters.lastWeekDates,
      getters.presentWeekDates,
    );
    if (response.every((res) => {
      return !res.error;
    })) {
      const lastResponse = response[0].response.data || empty;
      const presentResponse = response[1].response.data || empty;
      const injury = {
        last: lastResponse,
        present: presentResponse,
      };
      commit('setInjury', injury);
      return true;
    }
    return false;
  },
  async getHolidays({ commit, getters }) {
    const response = await weekly.getHolidays(
      getters.server,
      getters.lastWeekDates,
      getters.presentWeekDates,
    );
    const error = response.some((e) => {
      return e.error;
    });
    if (error) {
      return false;
    }
    let arr = [];
    response.forEach((e) => {
      if (e.response && e.response.data) {
        arr = arr.concat(e.response.data);
      }
    });
    commit('setHolidays', arr);
    return true;
  },
  async getPTO({ commit, getters }) {
    const response = await weekly.getPto(getters.user.emp_num, getters.server);
    if (response.unauthenticated) {
      this.dispatch('logout');
    }
    if (response.empty) {
      commit('setUser', { ...getters.user, pto: { availablePTO: '0', date_last_updated: '' } });
      return true;
    }
    if (!response.error) {
      commit('setUser', { ...getters.user, pto: response.response.data });
      return true;
    }
    return false;
  },

  setEmployeeStatus({ commit, getters }, status) {
    const newUserObj = { ...getters.user, status };
    commit('setUser', newUserObj);
  },

  // save entries
  async saveNewRecord({ commit, dispatch, getters }, entryObj) {
    const response = await weekly.saveEntry(entryObj, getters.server);
    if (response.message === 'unauthenticated') {
      dispatch('addToOfflineEntries', entryObj.entry);
      this.dispatch('logout');
    }
    if (response.error) {
      dispatch('addToOfflineEntries', entryObj.entry);
      commit('setSnackbar', {
        show: true,
        time: 3000,
        message: 'Network connection slow, added to offline',
        color: '#553882',
      });
    } else if (response.message === 'success' && response.status === 'success') {
      dispatch('getEntries');
      commit('setSnackbar', {
        show: true,
        time: 3000,
        message: 'Success',
        color: 'success',
      });
    }
    return response;
  },
  async sendEmail({ getters }, userData) {
    const response = await weekly.sendEmail(userData, getters.server);
    if (response.message === 'unauthenticated') {
      this.dispatch('logout');
    }
    return response;
  },
  async submitTimecard({ commit, getters }) {
    const userData = {
      emp_num: getters.user.emp_num,
      lastWeekOrPresentWeek: getters.week,
    };
    const response = await weekly.submitTimecard(userData, getters.server);
    if (response.message === 'unauthenticated') {
      this.dispatch('logout');
    }
    if (response.error) {
      commit('setSnackbar', {
        show: true,
        time: 3000,
        message: 'Network connection weak, please try again.',
        color: 'warning',
      });
    } else if (response.status === 'error') {
      commit('setSnackbar', {
        show: true,
        time: 3000,
        message: 'Error submitting time card, try again',
        color: 'error',
      });
    } else {
      commit('setSnackbar', {
        show: true,
        time: 3000,
        message: 'Submitted Timecard Successfully',
        color: 'primary',
      });
      commit('setLoading', true);
      await this.dispatch('getEntries');
      commit('setLoading', false);
    }
  },
  async saveEditRecord({ commit, dispatch, getters }, entryObj) {
    const response = await weekly.saveEntry(entryObj, getters.server);
    if (response.message === 'unauthenticated') {
      this.dispatch('logout');
    }
    if (response.error) {
      commit('setSnackbar', {
        show: true,
        time: 3000,
        message: 'Bad network connection, Cannot make Edit',
        color: '#553882',
      });
    } else if (response.message === 'success' && response.status === 'success') {
      dispatch('getEntries');
      commit('setSnackbar', {
        show: true,
        time: 3000,
        message: 'Success',
        color: 'success',
      });
    }
    return response;
  },
  async saveOfflineRecord({ commit, dispatch, getters }, entryObj) {
    const entries = {
      entry: entryObj.entry,
      data: {
        timecard_entries: entryObj.data.timecard_entries.map((entry) => {
          return { ...entry, id: null };
        }),
      },
    };
    const response = await weekly.saveEntry(entries, getters.server);
    if (response.message === 'unauthenticated') {
      entryObj.entry.originalDate = null;
      dispatch('addToOfflineEntries', entryObj.entry);
      this.dispatch('logout');
    }
    if (response.error) {
      entryObj.entry.originalDate = null;
      dispatch('deleteOfflineEntry', entryObj.entry);
      dispatch('addToOfflineEntries', entryObj.entry);
      commit('setSnackbar', {
        show: true,
        time: 3000,
        message: 'Bad Network Connection, Changed offline record, but did not save to centers',
        color: '#553882',
      });
    } else if (response.message === 'success' && response.status === 'success') {
      dispatch('deleteOfflineEntry', entryObj.entry);
      dispatch('getEntries');
      commit('setSnackbar', {
        show: true,
        time: 3000,
        message: 'Success',
        color: 'success',
      });
    }
    return response;
  },
  async deleteEntry({ getters }, entry) {
    const userData = {
      emp_num: getters.user.emp_num,
      id: entry.id,
    };
    const response = await weekly.deleteEntry(userData, getters.server);
    if (response.unauthenticated) {
      this.dispatch('logout');
    }
    if (response.error) {
      this.commit('setSnackbar', {
        show: true,
        time: 3000,
        message: 'Bad Network Connection, Record did not delete',
        color: 'alert',
      });
    } else if (response && !response.error) {
      this.dispatch('removeDeletedEntry', entry);
    }
    return response;
  },
  removeDeletedEntry({ state, commit }, entry) {
    const entries = [...state.entries[entry.date].entries];
    const index = entries.findIndex((e) => {
      return e.id === entry.id;
    });
    if (index >= 0) {
      entries.splice(index, 1);
      let total = 0;
      entries.forEach((e) => {
        total += Number(e.total);
      });
      const newObj = {
        ...state.entries,
        [entry.date]: {
          entries: [...entries],
          totalHours: total,
        },
      };
      commit('setEntries', newObj);
    }
  },
  // offline entries
  addToOfflineEntries({ state, commit, getters }, entry) {
    const existing = state.offline[getters.user.emp_num] || [];
    const offline = [...existing];
    entry.offline = true;
    entry.id = `offline-${offline.length}`;
    const recordIndex = offline.findIndex((rec) => {
      return rec.id === entry.id;
    });
    if (recordIndex >= 0) {
      // there is a record with id already
      offline.splice(recordIndex, 1, entry);
    } else {
      offline.push(entry);
    }
    commit('setSnackbar', { show: true, time: 2000, message: 'Added as Offline Entry' });
    commit('setOfflineEntries', { [getters.user.emp_num]: offline });
  },
  deleteOfflineEntry({ state, commit, getters }, entry) {
    const offline = state.offline[getters.user.emp_num];
    const index = offline.findIndex((dbRecord) => {
      return dbRecord.id === entry.id;
    });
    offline.splice(index, 1);
    commit('setSnackbar', { show: true, time: 2000, message: 'Deleted Offline Entry' });
    commit('setOfflineEntries', { [getters.user.emp_num]: offline });
  },

  // save comment / injury
  async saveComment({ commit, dispatch, getters }, userData) {
    const response = await weekly.saveComment(userData, getters.server);
    if (response.unauthenticated) {
      this.dispatch('logout');
    }
    if (response.error) {
      //timeout
      commit('setSnackbar', {
        show: true,
        time: 3000,
        message: 'Bad Network Connection, Comment Not Saved',
        color: 'alert',
      });
    } else {
      //success
      commit('setSnackbar', {
        show: true,
        time: 3000,
        message: 'Saved Weekly Comment',
        color: 'success',
      });
      dispatch('getComments');
    }
    return response;
  },
  async saveInjury({ commit, dispatch, getters }, userData) {
    const response = await weekly.saveInjury(userData, getters.server);
    if (response.error) {
      commit('setSnackbar', {
        show: true,
        time: 3000,
        message: 'Bad Network Connection, Injury Not Saved',
        color: 'alert',
      });
    } else {
      commit('setSnackbar', {
        show: true,
        time: 3000,
        message: 'Saved Injury Info',
        color: 'success',
      });
      dispatch('getInjury');
    }
    return response;
  },
  saveWeek({ commit }, week) {
    commit('saveWeek', week);
  },
};
