/* eslint-disable camelcase */
import fetchData from '../../../../utils/fetchData';
import fetchDataNewAPI from '../../../../mixins/fetchDataNewAPI';

export default {
  async fetchDataNew(apicstk, userData, server) {
    return fetchDataNewAPI.methods.fetchDataNew(apicstk, userData, { alternate_url: `${server}controller.php` });
  },
  getCrusherJobs(server) {
    const crusher_jobs_cstk = {
      center: 'pm',
      section: 'return',
      tab: 'all',
      kind: 'crusherJobs',
    };
    return fetchData(crusher_jobs_cstk, null, null, server);
  },
  getAllPublicJobs(server) {
    const public_jobs_cstk = {
      center: 'pm',
      section: 'return',
      tab: 'all',
      kind: 'publicJobs',
    };
    return fetchData(public_jobs_cstk, null, null, server);
  },
  getEmployeeSalariedStatus(emp_num, server) {
    const employee_salaried_cstk = {
      center: 'employee',
      section: 'return',
      tab: 'single',
      kind: 'employeeSalariedStatus',
    };
    return fetchData(
      employee_salaried_cstk,
      null,
      { emp_num },
      server,
    );
  },
  getPto(emp_num, server) {
    const pto_cstk = {
      center: 'employee',
      section: 'return',
      tab: 'single',
      kind: 'hoursAvailableForPTO',
    };
    return this.fetchDataNew(pto_cstk, { emp_num }, server);
  },

  getHolidays(server, lastWeek, thisWeek) {
    const holidays_cstk = {
      center: 'employee',
      section: 'return',
      tab: 'all',
      kind: 'paidHolidaysByDateRange',
    };
    const lastData = {
      start_date: lastWeek[0].date,
      end_date: lastWeek[6].date,
    };
    const presentData = {
      start_date: thisWeek[0].date,
      end_date: thisWeek[6].date,
    };
    return Promise.all([
      this.fetchDataNew(holidays_cstk, lastData, server),
      this.fetchDataNew(holidays_cstk, presentData, server),
    ]);
  },
  getEntries(emp_num, server) {
    const entries_cstk = {
      center: 'employee',
      section: 'return',
      tab: 'all',
      kind: 'timecardEntriesForWeek',
    };
    const present = {
      emp_num,
      lastWeekOrPresentWeek: 'present',
    };
    const last = {
      emp_num,
      lastWeekOrPresentWeek: 'last',
    };
    return Promise.all([
      fetchData(entries_cstk, null, last, server),
      fetchData(entries_cstk, null, present, server),
    ]);
  },
  getDefaults(empnum, server) {
    const defaultCstk = {
      center: 'employee',
      section: 'return',
      tab: 'single',
      kind: 'defaultsForEmployee',
    };
    return fetchData(defaultCstk, null, {
      emp_num: empnum,
    }, server);
  },
  getLastUsedData(emp_num, server) {
    const userData = {
      emp_num,
    };
    const job_cstk = {
      center: 'employee',
      section: 'return',
      tab: 'all',
      kind: 'last5UsedJobsForGivenEmployee',
    };
    const eq_cstk = {
      center: 'employee',
      section: 'return',
      tab: 'all',
      kind: 'last5UsedEqNumsForGivenEmployee',
    };
    return Promise.all([
      this.fetchDataNew(job_cstk, userData, server),
      this.fetchDataNew(eq_cstk, userData, server),
    ]);
  },
  getComments(emp_num, server, lastWeek, thisWeek) {
    const comment_cstk = {
      center: 'employee',
      section: 'return',
      tab: 'single',
      kind: 'employeeWeeklyComment',
    };
    const lastData = {
      date_start: lastWeek[0].date,
      date_end: lastWeek[6].date,
      emp_num,
    };
    const presentData = {
      date_start: thisWeek[0].date,
      date_end: thisWeek[6].date,
      emp_num,
    };
    return Promise.all([
      this.fetchDataNew(comment_cstk, lastData, server),
      this.fetchDataNew(comment_cstk, presentData, server),
    ]);
  },
  getInjuries(emp_num, server, lastDates, presentDates) {
    const injury_cstk = {
      center: 'employee',
      section: 'return',
      tab: 'single',
      kind: 'employeeWeeklyInjury',
    };
    const lastData = {
      date_start: lastDates[0].date,
      date_end: lastDates[6].date,
      emp_num,
    };
    const presentData = {
      date_start: presentDates[0].date,
      date_end: presentDates[6].date,
      emp_num,
    };
    return Promise.all([
      this.fetchDataNew(injury_cstk, lastData, server),
      this.fetchDataNew(injury_cstk, presentData, server),
    ]);
  },
  saveEntry(entry, server) {
    const entryCstk = {
      center: 'employee',
      section: 'set',
      tab: 'multiple',
      kind: 'timecardEntries',
    };
    return fetchData(entryCstk, null, entry.data, server, 7800);
  },
  deleteEntry(userData, server) {
    const delete_cstk = {
      center: 'employee',
      section: 'delete',
      tab: 'single',
      kind: 'unsubmittedTemporaryTimecardEntry',
    };
    return this.fetchDataNew(delete_cstk, userData, server);
  },
  submitTimecard(userData, server) {
    const submit_cstk = {
      center: 'employee',
      section: 'action',
      tab: 'all',
      kind: 'submitTemporaryTimecardEntriesForGivenWeek',
    };
    return this.fetchDataNew(submit_cstk, userData, server);
  },
  sendEmail(userData, server) {
    const submit_cstk = {
      center: 'hr',
      section: 'action',
      tab: 'multiple',
      kind: 'sendEmail',
    };
    return this.fetchDataNew(submit_cstk, userData, server);
  },
  saveComment(userData, server) {
    const save_comment_cstk = {
      center: 'employee',
      section: 'set',
      tab: 'single',
      kind: 'employeeWeeklyComment',
    };
    return this.fetchDataNew(save_comment_cstk, userData, server);
  },
  saveInjury(userData, server) {
    const save_injury_cstk = {
      center: 'employee',
      section: 'set',
      tab: 'single',
      kind: 'employeeWeeklyInjury',
    };
    return this.fetchDataNew(save_injury_cstk, userData, server);
  },
  saveDefaults(defaults, server) {
    const defaultsCstk = {
      center: 'employee',
      section: 'set',
      tab: 'single',
      kind: 'defaultsForEmployee',
    };
    return fetchData(
      defaultsCstk,
      null,
      defaults,
      server,
    );
  },
};
