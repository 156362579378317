<template>
  <v-container
    id="iftaOverview"
    style="min-height:90vh"
    class="d-flex flex-column justifiy-space-between"
    fluid
  >
    <!-- WEEKLY HOURS SUMMARY -->
    <v-row
      v-if="weekdays.length > 0"
      id="IFTAweeklySummary"
      dense
      justify="center"
    >
      <v-col
        xs="12"
        sm="10"
        md="8"
      >
        <v-card
          outlined
        >
          <v-app-bar
            class="pa-0"
            color="#e6e6e6"
            flat
          >
            <v-row
              class="pa-0"
              justify="center"
              align="center"
              dense
            >
              <v-row dense>
                <v-col class="text-center">
                  <span class="text-body-1 text-truncate">{{ weekRange }}</span>
                </v-col>
                <v-divider vertical />
                <v-col class="text-center">
                  <span class="text-body-1 text-truncate">Total: {{ weekHours.total }} Hrs.</span>
                </v-col>
              </v-row>
            </v-row>
          </v-app-bar>
          <v-card-actions>
            <v-col
              class="d-flex justify-space-between pa-0"
            >
              <v-btn
                v-if="isMobile"
                small
                depressed
                :loading="emailing"
                @click="emailTimecard"
              >
                <v-icon
                  small
                  left
                >
                  mdi-email
                </v-icon>email
              </v-btn>
              <v-btn
                v-if="isMobile"
                small
                depressed
                :loading="printing"
                @click="printTimecard"
              >
                <v-icon
                  small
                  left
                >
                  mdi-printer
                </v-icon>PRINT
              </v-btn>
              <v-btn
                v-else
                small
                depressed
                :loading="printing"
                @click="getTimecard"
              >
                <v-icon
                  small
                  left
                >
                  mdi-printer
                </v-icon>PRINT
              </v-btn>
              <v-btn
                small
                color="primary"
                class="d-flex pl-2 pr-2"
                depressed
                :loading="submitting"
                @click="checkForUnsaved"
              >
                <v-icon
                  small
                  class="pr-1"
                >
                  mdi-send
                </v-icon>submit
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- This week / Last week -->
    <v-row
      dense
      justify="center"
    >
      <v-col
        xs="12"
        sm="10"
        md="8"
      >
        <v-card
          outlined
          class="text-center"
        >
          <v-tabs
            :value="selectedWeek"
            fixed-tabs
            background-color="cyan"
            dark
            @change="setSelectedWeek($event)"
          >
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab :style="`${selectedWeek === 1 ? 'background-color: #bcbcbc; color: black' : ''}`">
              Last Week
            </v-tab>
            <v-tab :style="`${selectedWeek === 0 ? 'background-color: #bcbcbc; color: black' : ''}`">
              This Week
            </v-tab>
          </v-tabs>
          <!-- Selects the date -->
          <v-btn
            v-for="(date, i) in weekdays"
            :id="`${returnIdForDate(date)}`"
            :key="`day-select-${i}`"
            class="ma-1 pa-0"
            :color="`${selectedDate === date ? '#e2ecf7' : ''}`"
            @click="selectDate(date)"
          >
            {{ returnDayNameOfDate(date) }}: {{ weekHours.entries[i].hours }}
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <!-- ENTRIES  -->
    <v-row
      id="iftaEntries"
      justify="center"
    >
      <v-col
        xs="12"
        sm="10"
        md="8"
      >
        <v-card
          outlined
          flat
        >
          <v-row>
            <v-col class="text-center">
              <h2 class="subtitle-1">
                Entries for {{ dayDate }}
              </h2>
            </v-col>
          </v-row>
          <v-card-text class="text-center">
            <v-data-iterator
              :items="entries"
              :loading="refreshing || saving"
              loading-text="Loading Entries.."
              hide-default-footer
              no-data-text="No Entries For Selected Day"
            >
              <template #default="{items}">
                <v-row
                  v-for="(entry, index) in items"
                  :key="index"
                >
                  <v-col>
                    <!-- IFTA ENTRY -->
                    <v-card
                      v-if="entry.ifta_id"
                      :id="`ifta-record-${index}`"
                      flat
                      outlined
                      :class="{
                        notSubmitted: !entry.submitted,
                        submittedEntry: entry.submitted,
                        unsaved: entry.unsaved}"
                      @click="viewEntry(entry)"
                    >
                      <v-container fluid>
                        <v-row
                          dense
                          align="center"
                        >
                          <v-col xs="5">
                            <div
                              style="text-decoration: underline"
                              class="font-weight-bold"
                            >
                              Eqp #
                            </div>
                            {{ entry.eq_num || "None" }}
                          </v-col>
                          <v-col xs="3">
                            <div
                              style="text-decoration: underline"
                              class="font-weight-bold"
                            >
                              Drive
                            </div>
                            {{ entry.drive_time || "None" }}
                          </v-col>
                          <v-col xs="3">
                            <div
                              style="text-decoration: underline"
                              class="font-weight-bold"
                            >
                              Total
                            </div>
                            {{ entry.total_time || "None" }}
                          </v-col>
                          <v-col
                            v-if="entry.odometer_end"
                            xs="3"
                          >
                            <div
                              style="text-decoration: underline"
                              class="font-weight-bold"
                            >
                              Miles
                            </div>
                            {{ entry.miles_total }}
                          </v-col>
                          <v-col
                            v-else
                            xs="3"
                          >
                            <b style="color: red"> Missing</b> <br />
                            <b style="color: red">Miles</b>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>

                    <!-- Non-Ifta Entry ex: 'pto', 'off', 'holiday' -->
                    <v-card
                      v-else
                      flat
                      outlined
                      :class="{ notSubmitted: !entry.submitted, submittedEntry: entry.submitted}"
                    >
                      <v-container fluid>
                        <v-row
                          dense
                          align="center"
                        >
                          <v-col xs="4">
                            <div
                              style="text-decoration: underline"
                              class="font-weight-bold"
                            >
                              Type
                            </div>
                            <div>{{ entry.description }}</div>
                          </v-col>
                          <v-col xs="3">
                            <div
                              style="text-decoration: underline"
                              class="font-weight-bold"
                            >
                              Hours
                            </div>
                            <div>{{ entry.total }}</div>
                          </v-col>
                          <v-col xs="3">
                            <div
                              style="text-decoration: underline"
                              class="font-weight-bold"
                            >
                              Cost Code
                            </div>
                            <div>{{ entry.cstcde }}</div>
                          </v-col>
                          <v-col xs="2">
                            <v-btn
                              :loading="deletingSpecial"
                              icon
                              :disabled="entry.submitted !== null"
                              @click.stop="deleteSpecialRecord(entry.id)"
                            >
                              <v-icon color="red">
                                mdi-close
                              </v-icon>
                            </v-btn>
                            <v-btn
                              v-if="entry.cstcde === '140.000'"
                              :loading="deletingSpecial"
                              icon
                              :disabled="entry.submitted !== null"
                              @click.stop="editPto(entry)"
                            >
                              <v-icon color="red">
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Weekly Comment and injury report -->
    <v-col
      class="d-flex mb-8"
      justify="center"
    >
      <v-btn
        id="commentButton"
        small
        class="flex-grow-1"
        @click="commentPopup = true"
      >
        <v-icon
          v-if="$store.getters.comment[$store.getters.week].comment"
          small
          left
        >
          mdi-checkbox-marked-outline
        </v-icon>
        <v-icon
          v-else
          small
          left
        >
          mdi-checkbox-blank-outline
        </v-icon>
        Comment
      </v-btn>
      <v-btn
        id="injuryButton"
        class="flex-grow-1 ml-5"
        small
        @click="injuryPopup = true"
      >
        <v-icon
          v-if="$store.getters.injury[$store.getters.week].injury_comment"
          small
          left
        >
          mdi-checkbox-marked-outline
        </v-icon>
        <v-icon
          v-else
          small
          left
        >
          mdi-checkbox-blank-outline
        </v-icon>
        Injury
      </v-btn>

      <!-- When changing this footer, be sure to test on multiple device sizes -->
      <v-footer
        id="iftaOverViewFooter"
        fixed
        app
        class="d-flex justify-space-between pa-0"
        color="transparent"
      >
        <v-btn
          id="iftaOff"
          color="orange"
          small
          tile
          class="flex-grow-1 mx-1 pa-0 rounded-t"
          @click="handleOFF"
        >
          <v-icon small>
            mdi-plus
          </v-icon>off
        </v-btn>

        <v-btn
          id="iftaHoliday"
          color="yellow"
          small
          tile
          class="flex-grow-1 mx-1 pa-0 rounded-t"
          :disabled="disableHoliday > 0"
          @click="handleHoliday"
        >
          <v-icon small>
            mdi-plus
          </v-icon>holiday
        </v-btn>

        <v-btn
          id="iftaPTO"
          color="blue"
          small
          tile
          class="flex-grow-1 mx-1 pa-0 rounded-t"
          @click="handlePTO"
        >
          <v-icon small>
            mdi-plus
          </v-icon>pto
        </v-btn>
        <v-btn
          id="add-ifta-record-btn"
          small
          tile
          class="flex-grow-1 mx-1 pa-0 rounded-t"
          depressed
          color="success"
          @click="addNewEntry"
        >
          <v-icon
            left
            small
          >
            mdi-clock-fast
          </v-icon>Regular
        </v-btn>
      </v-footer>
    </v-col>
    <!-- Comment POPUP -->
    <v-dialog
      v-model="commentPopup"
      persistent
      max-width="90%"
      width="650px"
      class="mx-2"
    >
      <CommentForm
        v-if="commentPopup"
        :comment="$store.getters.comment[$store.getters.week].comment"
        @closeComment="closeComment"
      />
    </v-dialog>

    <!-- PTO CARD -->
    <v-dialog
      v-model="addPto"
      width="90%"
      max-width="600px"
    >
      <v-card
        v-if="addPto"
        id="pto-record-card"
        class="pa-2"
      >
        <v-col class="d-flex justify-center">
          <div
            style="text-decoration: underline"
            class="font-weight-bold pr-2"
          >
            Type
          </div>PTO
        </v-col>
        <v-text-field
          id="enter-pto-input"
          v-model="ptoToUse"
          type="number"
          step=".25"
          single-line
          :label="`${pto.availablePTO || 0} hrs available`"
          :error-messages="ptoErrorMessage()"
        />
        <v-card-actions
          class="d-flex justify-end pa-2"
        >
          <v-btn
            id="iftaCancelPTO"
            class="mr-2"
            color="error"
            small
            @click="cancelPTO"
          >
            cancel
          </v-btn>
          <v-btn
            id="iftaSavePTO"
            :loading="savingPTO"
            small
            color="success"
            :disabled="ptoErrorMessage().length > 0"
            @click="savePTO"
          >
            save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- OFF CARD -->
    <v-dialog
      v-model="addOFF"
      width="90%"
      max-width="600px"
    >
      <v-card
        v-if="addOFF"
        id="off-record-card"
        class="pa-2"
      >
        <v-col class="d-flex justify-center">
          <div
            style="text-decoration: underline"
            class="font-weight-bold mr-2"
          >
            Type:
          </div>OFF
        </v-col>
        <v-col class="d-flex justify-center">
          <div
            style="text-decoration: underline"
            class="font-weight-bold mr-2"
          >
            Hours:
          </div>
          <div>0</div>
        </v-col>
        <v-col class="d-flex justify-center">
          <div
            style="text-decoration: underline"
            class="font-weight-bold mr-2"
          >
            Cost Code:
          </div>
          <div>0.000</div>
        </v-col>
        <v-card-actions class="d-flex justify-end pa-2">
          <v-btn
            id="cancel-off-btn"
            small
            class="mr-2"
            color="error"
            @click="cancelOFF"
          >
            cancel
          </v-btn>
          <v-btn
            id="save-off-btn"
            :loading="savingOFF"
            small
            color="success"
            @click="saveOFF"
          >
            save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Holiday Card -->
    <v-dialog
      v-model="addHoliday"
      width="90%"
      max-width="600px"
    >
      <v-card
        v-if="addHoliday"
        id="holiday-record-card"
      >
        <v-container fluid>
          <div
            style="text-decoration: underline"
            class="font-weight-bold"
          >
            Type
          </div>Holiday

          <div
            style="text-decoration: underline"
            class="font-weight-bold"
          >
            Hours
          </div>
          <div>{{ $store.getters.user.status === 'fullTime' ? 8 : 0 }}</div>

          <div
            style="text-decoration: underline"
            class="font-weight-bold"
          >
            Cost Code
          </div>
          <div>160.000</div>
        </v-container>
        <v-card-actions class="d-flex justify-end pa-2">
          <v-btn
            color="error"
            small
            @click="cancelHoliday"
          >
            cancel
          </v-btn>
          <v-btn
            :loading="savingHoliday"
            small
            color="success"
            @click="saveHoliday"
          >
            save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- INJURY POPUP -->
    <v-dialog
      v-model="injuryPopup"
      persistent
      max-width="90%"
      width="650px"
      class="mx-2"
    >
      <InjuryForm
        v-if="injuryPopup"
        :injury="$store.getters.injury[$store.getters.week]"
        @closeInjury="closeInjury"
      />
    </v-dialog>
    <Alertbox ref="alertbox" />
  </v-container>
</template>
<script>
/* eslint-disable vue/no-undef-properties */
import moment from 'moment/src/moment';
import InjuryForm from '../weekly/components/injury_form.vue';
import CommentForm from '../weekly/components/comment_form.vue';
import Alertbox from '../alert/alert.vue';

export default {
  name: 'IftaApp',
  components: {
    InjuryForm,
    CommentForm,
    Alertbox,
  },
  data() {
    return {
      emailing: false,
      printing: false,
      selectedWeek: 0,
      refreshing: false,
      saving: false,
      addPto: false,
      addOFF: false,
      savingOFF: false,
      savingPTO: false,
      deletingSpecial: false,
      ptoToUse: null,
      savingHoliday: false,
      addHoliday: false,
      submitting: false,
      selectedDate: null,
      days: [],
      week: 'present',
      weekdays: [],
      specialEntries: [],
      injuryPopup: false,
      commentPopup: false,
      editingPtoId: null,
      amountOfPtoToSubtract: 0,
    };
  },
  computed: {
    disableHoliday() {
      const numberOfNonIftaRecords = [];
      this.entries.forEach((e) => {
        if (e.cstcde && e.cstcde === '160.000') {
          numberOfNonIftaRecords.push(e);
        }
      });
      return numberOfNonIftaRecords.length;
    },
    isMobile() {
      return cordova.platformId !== 'browser';
    },

    entries() {
      if (this.saving || this.refreshing) {
        return [];
      }
      const entries = this.$store.getters.iftaOverview;
      const unsaved = JSON.parse(JSON.stringify(this.$store.getters.unsavedIftaRecords));
      const formatted = unsaved.map((e) => {
        const obj = e.record;
        obj.ifta_id = obj.id;
        obj.unsaved = true;
        obj.drive_time = this.determineDriveTime(e.entries);
        obj.total_time = this.determineTotalTime(e.entries);
        return obj;
      });
      const all = [...entries, ...formatted];
      return all.filter((e) => {
        return e.date === this.selectedDate;
      });
    },

    dayHours() {
      if (this.weekHours.entries) {
        const weekday = moment(this.selectedDate).format('d');
        return this.weekHours.entries[weekday].hours;
      }
      return 0;
    },
    dayDate() {
      return `${moment(this.selectedDate).format('MMMM Do')}`;
    },
    weekRange() {
      return `${moment(this.weekdays[0])
        .format('MM/DD')} - ${moment(this.weekdays[this.weekdays.length - 1])
        .format('MM/DD')}`;
    },
    weekHours() {
      let weekTotal = 0;
      const days = this.weekdays || [];
      const entries = days.map((day, index) => {
        let total = 0;
        const allEntries = this.$store.getters.entries;
        const dayEntries = (allEntries[day] || {}).entries || [];
        dayEntries.forEach((entry) => {
          total += Number(entry.total);
        });
        weekTotal += total;
        return { name: moment.weekdaysShort()[index], hours: total };
      });

      return { entries, total: weekTotal };
    },
    pto() {
      return this.$store.getters.user.pto || { availablePTO: 0, date_last_updated: '' };
    },
  },
  watch: {
    selectedDate() {
      this.addOFF = false;
      this.addPto = false;
      this.ptoToUse = null;
    },
    '$store.getters.loading': function checkforRefresh() {
      if (!this.$store.getters.loading) {
        this.getIftaEntries();
      }
    },
  },
  async mounted() {
    this.selectedDate = moment().format('YYYY-MM-DD');
    this.setUpWeekSelector();
    this.getIftaEntries();
    const newDate = this.$store.getters.newDate;
    if (newDate) {
      this.selectDate(newDate);
    } else {
      this.selectDate(this.selectedDate);
    }
    this.$store.dispatch('getEquipmentForIfta');
    this.$store.dispatch('getTrailersForIfta');
  },
  methods: {
    returnIdForDate(date) {
      return moment(date).format('MMMM-D');
    },
    setUpWeekSelector() {
      this.makeWeekDates(this.selectedDate);
      this.selectedWeek = (this.$store.getters.week === 'last') ? 0 : 1;
    },
    returnDayNameOfDate(date) {
      return moment(date).format('ddd');
    },
    viewEntry(entry) {
      this.$store.commit('setViewIftaId', entry.ifta_id);
      this.$store.commit('setUnsavedStatus', Boolean(entry.unsaved));
      if (this.$route.path !== `/ifta/${entry.ifta_id}`) {
        this.$router.push(`/ifta/${entry.ifta_id}`).catch(() => {});
      }
    },
    addNewEntry() {
      const id = `new-${moment().format('X')}`;
      this.$store.commit('setViewIftaId', id);
      this.$store.commit('setUnsavedStatus', false); // Set to false on purpose, not unsaved, just new.
      this.$store.commit('setNewDate', this.selectedDate);
      this.$router.push(`ifta/${id}`).catch(() => {});
    },
    determineDriveTime(entries) {
      // Reduce it
      return entries.reduce((acc, val) => {
        if (Number(val.driving_record)) {
          return acc + Number(val.total);
        }
        return acc;
      }, 0).toFixed(2);
    },
    determineTotalTime(entries) {
      return entries.reduce((acc, val) => {
        return acc + Number(val.total);
      }, 0).toFixed(2);
    },
    //HOLIDAY methods
    handleHoliday() {
      const todaysHoliday = this.$store.getters.holidays.find((e) => {
        return e.holiday_date === this.selectedDate;
      });
      if (todaysHoliday && !Number(todaysHoliday.unpaid)) {
        const total = (this.$store.getters.user.status === 'fullTime') ? 8 : 0;
        const time = this.$store.shared.entries[this.selectedDate].totalHours;
        if (time + total > 24) {
          this.userAlert(`Adding this holiday will put you over 24 hours, 
          please verify that your other records are correct`);
        } else {
          this.addHoliday = true;
        }
      } else {
        this.userAlert(`The selected day is not a paid holiday. However, if the office is closed for this day, 
        choose another option such as "Off" or "PTO"`);
      }
    },
    cancelHoliday() {
      this.addHoliday = false;
    },
    async saveHoliday() {
      this.savingHoliday = true;
      const record = {
        id: null,
        date: this.selectedDate,
        emp_num: this.$store.getters.user.emp_num,
        cstcde: '160.000',
        job_num: null,
        description: 'Holiday',
        start: null,
        stop: null,
        lunch: null,
        total: this.$store.getters.user.status === 'fullTime' ? 8 : 0,
        eq_num: null,
        eqphrs: null,
        paygroup: null,
        payrate: null,
        offsite: null,
      };
      const response = await this.$store.dispatch('savePTOorOFFRecord', record);
      if (response.message === 'success' && response.status === 'success') {
        await this.getIftaEntries();
        await this.$store.dispatch('getEntries');
        this.addHoliday = false;
      }
      this.savingHoliday = false;
    },
    //OFF Methods
    handleOFF() {
      this.addOFF = true;
    },
    cancelOFF() {
      this.addOFF = false;
    },
    async saveOFF() {
      this.savingOFF = true;
      const record = {
        id: null,
        date: this.selectedDate,
        emp_num: this.$store.getters.user.emp_num,
        cstcde: '0.000',
        job_num: null,
        description: 'OFF',
        start: null,
        stop: null,
        lunch: null,
        total: 0,
        eq_num: null,
        eqphrs: null,
        paygroup: null,
        payrate: null,
        offsite: null,
      };
      const response = await this.$store.dispatch('savePTOorOFFRecord', record);
      if (response.message === 'success' && response.status === 'success') {
        await this.getIftaEntries();
        this.addOFF = false;
      } else {
        this.userAlert(
          'Record did not save, please check your connection and try again.',
        );
      }
      this.savingOFF = false;
    },
    // PTO Methods
    handlePTO() {
      this.addPto = true;
    },
    cancelPTO() {
      this.addPto = false;
      this.ptoToUse = null;
      this.editingPtoId = null;
      this.amountOfPtoToSubtract = 0;
    },
    editPto(entry) {
      this.addPto = true;
      this.ptoToUse = entry.total;
      this.editingPtoId = entry.id;
      this.amountOfPtoToSubtract = entry.total;
    },
    ptoErrorMessage() {
      const errors = [];
      if (this.savingPTO) {
        return errors;
      }
      if (Number(this.ptoToUse) > Number(this.pto.availablePTO)) {
        errors.push(`Can't exceed ${this.pto.availablePTO} hrs of PTO.`);
      }
      if (Number(this.ptoToUse) + Number(this.dayHours - this.amountOfPtoToSubtract) > 24) {
        errors.push('Can\'t have more than 24 hours in a day.');
      }
      if (Number(this.ptoToUse) % 0.25 > 0) {
        errors.push('Must be .25 increment');
      }
      return errors;
    },
    async savePTO() {
      this.savingPTO = true;
      this.saving = true;
      const record = {
        id: this.editingPtoId || null,
        date: this.selectedDate,
        emp_num: this.$store.getters.user.emp_num,
        cstcde: '140.000',
        job_num: null,
        description: 'PTO',
        start: null,
        stop: null,
        lunch: null,
        total: this.ptoToUse,
        eq_num: null,
        eqphrs: null,
        paygroup: null,
        payrate: null,
        offsite: null,
      };
      const response = await this.$store.dispatch('savePTOorOFFRecord', record);
      if (response.message === 'success' && response.status === 'success') {
        await this.$store.dispatch('getEntries');
        await this.getIftaEntries();
        this.addPto = false;
        this.ptoToUse = null;
        this.editingPtoId = null;
        this.amountOfPtoToSubtract = 0;
      } else {
        let errors = '';
        if (response.data) {
          response.data.forEach((error) => {
            let ptoErrors = '';
            Object.keys(error.failed_fields).forEach((fail) => {
              ptoErrors += error.failed_fields[fail][0];
            });
            errors += `${ptoErrors} `;
          });
        }

        let message = errors;
        if (!errors) {
          message = `An error has occurred: ${response.message}`;
        }
        this.userAlert(message);
      }
      this.savingPTO = false;
      this.saving = false;
    },
    //delete OFF or PTO or Holiday entries.
    async deleteSpecialRecord(id) {
      this.deletingSpecial = true;
      const response = await this.$store.dispatch('deleteIftaEntry', id);
      if (response.status === 'success' && response.message === 'success') {
        const index = this.specialEntries.findIndex((e) => {
          return e.id === id;
        });
        this.specialEntries.splice(index, 1);
        await this.$store.dispatch('getEntries');
        this.getIftaEntries();
      } else {
        this.userAlert(
          'Record not deleted, please check your connection and try again.',
        );
      }
      this.deletingSpecial = false;
    },

    //Uses vuetify's calendar first day and last day to get ifta entries for whole month
    async getIftaEntries() {
      this.refreshing = true;
      await this.$store.dispatch('getIftaOverview');
      this.refreshing = false;
    },

    // determine if last or present week dates
    async checkForUnsaved() {
      const weekDates = this.$store.getters.dates.map((e) => {
        return e.date;
      });
      const unsaved = this.$store.getters.unsavedIftaRecords.filter((e) => {
        return weekDates.includes(e.date);
      });
      const savedEntries = this.$store.getters.iftaOverview;
      const online = this.$store.getters.online;
      const missingEndingMiles = this.checkForMissingEndingMiles(savedEntries);
      if (!online) {
        this.userAlert('Unable to submit when offline or with a weak connection.');
      } else if (missingEndingMiles) {
        this.userAlert('Missing ending miles on at least 1 IFTA record');
      } else if (unsaved.length > 0) {
        this.userAlert(
          'Submitting will delete all unsaved (Purple) records from this week, do you want to continue submitting?',
          true,
        );
      } else {
        const res = await this.userAlert('Do you wish to submit?, you will not be able to make any more changes', true);
        if (res) {
          this.handleSubmit();
        }
      }
    },

    // Returns true if given object has a an ifta entry missing equipment ending miles
    checkForMissingEndingMiles(entries) {
      for (let i = 0; i < entries.length; i++) {
        const el = entries[i];
        if (el.ifta_id && !el.odometer_end) {
          return true;
        }
      }
      return false;
    },

    handleSubmit() {
      const lastArray = this.$store.getters.lastWeekDates.map((d) => {
        return d.date;
      });
      if (this.weekdays[0] === lastArray[0]) {
        return this.submitEntries({
          emp_num: this.$store.getters.user.emp_num,
          lastWeekOrPresentWeek: 'last',
        });
      }
      return this.submitEntries({
        emp_num: this.$store.getters.user.emp_num,
        lastWeekOrPresentWeek: 'present',
      });
    },
    //Handles injury report and weekly comment
    closeInjury() {
      this.injuryPopup = false;
    },
    closeComment() {
      this.commentPopup = false;
    },
    // submit entries to centers
    async submitEntries(userData) {
      this.submitting = true;
      this.saving = true;
      const res = await this.$store.dispatch('submitIftaTimecard', userData);
      if (res.status === 'success' && res.message === 'success') {
        this.$store.commit('setSnackbar', {
          show: true,
          time: 3000,
          message: 'Submitted Timecard Successfully',
          color: 'primary',
        });
        this.clearUnsavedForWeek();
        await this.getIftaEntries();
        await this.$store.dispatch('getEntries');
      } else if (res.status === 'success' && res.message !== 'success') {
        this.$store.commit('setSnackbar', {
          show: true,
          time: 3000,
          message: res.message,
          color: 'warning',
        });
      } else if (!res.status) {
        this.$store.commit('setSnackbar', {
          show: true,
          time: 3000,
          message: 'Unable to submit when offline or with a weak connection.',
          color: '#553882',
        });
      } else {
        this.$store.commit('setSnackbar', {
          show: true,
          time: 3000,
          message: 'Timecard not submitted',
          color: 'error',
        });
      }
      this.submitting = false;
      this.saving = false;
    },
    clearUnsavedForWeek() {
      const unsaved = this.$store.getters.unsavedIftaRecords;
      const weekDates = this.$store.getters.dates.map((e) => {
        return e.date;
      });
      const filtered = unsaved.filter((e) => {
        return !weekDates.includes(e.date);
      });
      this.$store.commit('setUnsavedRecords', filtered);
    },

    // called when user selects a day
    selectDate(date) {
      this.selectedDate = date;
      this.$store.commit('setNewDate', date);
      this.makeWeekDates(date);
      this.handleWeek(date);
    },

    //make dates of the week in an array
    makeWeekDates(eventDate) {
      const startOfWeek = moment(eventDate).startOf('week');
      const endOfWeek = moment(eventDate).endOf('week');
      const days = [];
      let day = startOfWeek;
      while (day <= endOfWeek) {
        days.push(day.toISOString().substr(0, 10));
        day = day.clone().add(1, 'd');
      }
      this.weekdays = days;
    },
    handleWeek(date) {
      const currentWeek = moment().week();
      const activeWeek = moment(date).week();
      const week = currentWeek - activeWeek === 1 ? 'last' : 'present';
      this.selectedWeek = (week === 'last') ? 0 : 1;
      this.week = week;
      this.$store.dispatch('saveWeek', week);
    },
    setSelectedWeek(week) {
      const dateFromWeek = (week === 0) ? moment().day(-7).format('YYYY-MM-DD') : moment().day(1).format('YYYY-MM-DD');
      this.makeWeekDates(dateFromWeek);
      this.handleWeek(dateFromWeek);
      this.getIftaEntries();
    },

    //PDF Methods
    async emailTimecard() {
      this.emailing = true;
      const userData = JSON.stringify({
        startDate: this.weekdays[0],
        endDate: this.weekdays[6],
        empNumArray: [this.$store.getters.user.emp_num],
      });
      const params = {
        center: 'employee',
        section: 'action',
        tab: 'multiple',
        kind: 'generateTimecardForDownload',
        dataType: 'json',
        userData,
      };
      const query = Object.keys(params)
        .map((key) => {
          return `${encodeURIComponent(key)}=${encodeURIComponent(
            params[key],
          )}`;
        })
        .join('&');
      const res = await fetch(
        `${this.$store.getters.server}controller.php?${query}`,
        {
          credentials: 'include',
          method: 'GET',
        },
      );
      const newBlob = await res.blob();
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        cordova.plugins.email.open({
          subject: `Timecard for dates ${this.weekdays[0]} to ${this.weekdays[6]}`,
          attachments: [`base64:timecard.pdf//${data.split(',')[1]}`],
        });
      };
      reader.readAsDataURL(newBlob);
      this.emailing = false;
    },
    async printTimecard() {
      this.printing = true;
      const userData = JSON.stringify({
        startDate: this.weekdays[0],
        endDate: this.weekdays[6],
        empNumArray: [this.$store.getters.user.emp_num],
      });
      const params = {
        center: 'employee',
        section: 'action',
        tab: 'multiple',
        kind: 'generateTimecardForDownload',
        dataType: 'json',
        userData,
      };
      const query = Object.keys(params)
        // eslint-disable-next-line sonarjs/no-identical-functions
        .map((key) => {
          return `${encodeURIComponent(key)}=${encodeURIComponent(
            params[key],
          )}`;
        })
        .join('&');
      const res = await fetch(
        `${this.$store.getters.server}controller.php?${query}`,
        {
          credentials: 'include',
          method: 'GET',
        },
      );
      const newBlob = await res.blob();
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        window.plugins.PrintPDF.print({
          data: `base64://${data.split(',')[1]}`,
          type: 'Data',
        });
      };
      reader.readAsDataURL(newBlob);
      this.printing = false;
    },
    async getTimecard() {
      const userData = JSON.stringify({
        startDate: this.weekdays[0],
        endDate: this.weekdays[6],
        empNumArray: [this.$store.getters.user.emp_num],
      });
      const params = {
        center: 'employee',
        section: 'action',
        tab: 'multiple',
        kind: 'generateTimecardForDownload',
        dataType: 'json',
        userData,
      };
      const query = Object.keys(params)
        // eslint-disable-next-line sonarjs/no-identical-functions
        .map((key) => {
          return `${encodeURIComponent(key)}=${encodeURIComponent(
            params[key],
          )}`;
        })
        .join('&');
      const res = await fetch(
        `${this.$store.getters.server}controller.php?${query}`,
        {
          credentials: 'include',
          method: 'GET',
        },
      );

      const newBlob = await res.blob();
      const dataUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = 'timecard.pdf';
      link.click();
    },
    async userAlert(message, confirm = false, isAllowingLinks = true) {
      this.processing = false;
      return this.$refs.alertbox.show(message, confirm, isAllowingLinks);
    },
  },
};
</script>
<style>
.noEntries {
  color: red !important;
}
.notSubmitted {
  background-color: yellow !important;
}
.submittedEntry {
  background: #1976d2 !important;
  color: white !important;
}
.unsaved {
  background-color: purple !important;
  color: white !important;
}
.v-btn--round{
  max-width:80%;
  max-height:74%;
  margin-top:3px;
}
.footer {
  padding-bottom: env(safe-area-inset-bottom);
}
</style>
