import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import localForage from 'localforage';
import weeklyModule from '../weekly/store/state';
import navigationModule from '../navigation/store/state';
import iftaModule from '../ifta/store/state';
import homeModule from '../home/store/state';
import fuelerStore from '../fueler/store/state';
import sharedModule from './sharedModule';

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: localForage,
  asyncStorage: true,
  reducer: (state) => {
    return {
      shared: state.shared,
      weekly: state.weekly,
      ifta: state.ifta,
      navigation: state.navigation,
      home: state.home,
      centers: state.centers,
    };
  },
});

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  modules: {
    shared: sharedModule,
    weekly: weeklyModule,
    ifta: iftaModule,
    navigation: navigationModule,
    home: homeModule,
    fueler: fuelerStore,
  },
});

export default store;
