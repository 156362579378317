import driver from '../api';

export default {
  getUnsavedRecord({ state, commit }, id) {
    const unsaved = state.unsavedIftaRecords;
    const found = unsaved.find((e) => {
      return e.record.id === id;
    });
    if (found) {
      commit('set_selected_record', found);
    } else {
      commit('set_selected_record', null);
    }
  },
  async savePTOorOFFRecord({ getters }, entry) {
    const response = await driver.saveSpecialEntry(entry, getters.server);
    if (response.message === 'unauthenticated') {
      this.dispatch('logout');
    }
    return response;
  },
  async getEquipmentForIfta({ getters, commit }) {
    const response = await driver.getIftaEquipment(getters.server);
    if (response.message === 'unauthenticated') {
      this.dispatch('logout');
    }
    if (response.status === 'success') {
      commit('setIftaEquipment', response.data || []);
      return true;
    }
    return false;
  },
  async getTrailersForIfta({ getters, commit }) {
    const response = await driver.getIftaTrailers(getters.server);
    if (response.message === 'unauthenticated') {
      this.dispatch('logout');
    }
    if (response.status === 'success') {
      commit('setIftaTrailers', response.data || []);
      return true;
    }
    return false;
  },

  async getIftaOverview({ getters, commit }) {
    const dates = getters.dates;
    const userData = {
      emp_num: getters.user.emp_num,
      start_date: dates[0].date,
      end_date: dates[6].date,
    };
    const response = await driver.getIftaOverview(userData, getters.server);
    if (!response.error && !response.empty) {
      commit('setIftaOverview', response.response.data);
    } else if (!response.error) {
      commit('setIftaOverview', []);
    } else if (response.unauthenticated) {
      this.dispatch('logout');
      // server error or something
    }
    return response;
  },
  async getIftaRecord({ getters, commit }, userData) {
    const response = await driver.getIftaRecord(userData, getters.server);
    if (!response.error && !response.empty) {
      commit('set_selected_record', response.response.data);
    } else if (response.unauthenticated) {
      this.dispatch('logout');
    } else { // Something went wrong.
      commit('set_selected_record', null);
    }
    return response;
  },
  async saveDriverRecord({ getters }, record) {
    return driver.saveIftaRecord(record, getters.server);
  },
  async deleteIfta({ getters }, id) {
    return driver.deleteIftaRecord(id, getters.server);
  },
  async deleteIftaEntry({ getters }, id) {
    return driver.deleteiftaTimecardEntry(id, getters.user.emp_num, getters.server);
  },
  async getMyVehicle({ getters }) {
    const response = await driver.getMyIftaVehicle(getters.user.emp_num, getters.server);
    if (response.message === 'unauthenticated') {
      this.dispatch('logout');
    }
    return (response.data || {}).eq_num || '';
  },
  async getMiles({ getters }, eq) {
    return driver.getOdometerForVehicle(eq, getters.server);
  },
  async submitIftaTimecard({ getters }, userData) {
    const response = await driver.submitAllRecords(userData, getters.server);
    if (response.message === 'unauthenticated') {
      this.dispatch('logout');
    }
    return response;
  },
  async getLastUsedInfo({ commit, getters }) {
    const response = await driver.getLastUsedData(getters.user.emp_num, getters.server);
    if (response.some((res) => {
      return res.unauthenticated;
    })) {
      this.dispatch('logout');
    }
    if (response.every((res) => {
      return (!res.error);
    })) {
      const lastObj = {
        jobs: response[0].response.data || [],
        equipment: response[1].response.data || [],
      };
      commit('setLastUsedInfo', lastObj);
      return true;
    }
    return false;
  },
};
