<template>
  <v-app>
    <v-fade-transition>
      <router-view></router-view>
    </v-fade-transition>
  </v-app>
</template>
<script>
export default {
  name: 'App',
};
</script>
<style scoped>
.offsite.v-input--selection-controls.v-input--checkbox.primary--text div.v-input__control div.v-input__slot {
  margin: 0px;
  padding: 0px;
}
</style>
