export default {
  iftaOverview(state) {
    return state.iftaOverview;
  },
  unsavedIftaRecords(state) {
    return state.unsavedIftaRecords;
  },
  newDate(state) {
    return state.newDate;
  },
  iftaEquipment(state) {
    return state.iftaEquipment;
  },
  iftaTrailers(state) {
    return state.iftaTrailers;
  },
  selectedRecord(state) {
    return state.selectedRecord;
  },
  viewingIftaId(state) {
    return state.viewingIftaId;
  },
  viewingUnsaved(state) {
    return state.viewingUnsaved;
  },
  lastUsedInfo(state) {
    return state.lastUsedInfo;
  },
};
