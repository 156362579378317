export default {
  crusherJobs(state) {
    return state.crusherJobs;
  },
  isSalaried(state) {
    return !!state.isSalaried;
  },
  entries(state) {
    return state.entries;
  },
  offlineEntries(state, getters) {
    const obj = {};
    const entries = state.offline[getters.user.emp_num] || [];
    entries.forEach((rec) => {
      if (obj[rec.date]) {
        obj[rec.date].push(rec);
      } else {
        obj[rec.date] = [rec];
      }
    });
    return obj;
  },
  comment(state) {
    return state.comment;
  },
  injury(state) {
    return state.injury;
  },
  holidays(state) {
    return state.holidays;
  },
  usedTimeForDay(state, getters) {
    if (state.selectedDay) {
      // eslint-disable-next-line prefer-const
      let { entries, totalHours } = getters.entries[state.selectedDay] || { entries: [], totalHours: 0 };
      const allEntries = [...entries];
      if (state.timecardEntry.id) {
        const index = allEntries.findIndex((entry) => {
          return (entry.id === state.timecardEntry.id);
        });
        if (index >= 0) {
          totalHours -= parseFloat(allEntries[index].total);
          allEntries.splice(index, 1);
        }
      }
      const times = [];
      allEntries.forEach((entry) => {
        if (entry.start) {
          times.push({ start: entry.start, stop: entry.stop });
        }
      });
      return { startStops: times, totalDayHours: totalHours };
    }
    return { startStops: [], totalDayHours: 0 };
  },
  week(state) {
    return state.week;
  },
};
