/* eslint-disable no-param-reassign */
import actions from './actions';
import getters from './getters';

export default {
  state: {
    navigation: {
      display: false,
      loading: false,
    },
    updateApp: {
      update: false,
      display: false,
    },

    // importedTemplates: [],
  },
  mutations: {
    setNavigation(state, navigation) {
      state.navigation = { ...navigation };
    },
    setUpdateApp(state, update) {
      state.updateApp = { ...update };
    },
    setImportedTemplates(state, arrayOfImported) {
      state.importedTemplates = arrayOfImported;
    },
  },
  actions,
  getters,
};
