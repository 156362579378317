import api from '../api';

export default {

  setNavigation({ commit }, navigation) {
    commit('setNavigation', navigation);
  },
  setUpdateApp({ commit }, update) {
    commit('setUpdateApp', update);
  },
  refresh({ dispatch, commit }) {
    dispatch('initialize')
      .then(
        commit('setSnackbar', {
          show: true,
          time: 1000,
          message: 'Refreshed Data',
          color: 'primary',
        }),
      );
  },
  getLatestAppVersion({ commit, getters, dispatch }) {
    if (getters.online) {
      dispatch('setNavigation', { loading: true });
      api.getLatestAppVersion(getters.server).then((response) => {
        if (response.status === 'success') {
          const current = getters.currentVersion.split('.').join('');
          const latest = response.message.split('.').join('');

          if (Number(latest) > Number(current)) {
            dispatch('setUpdateApp', {
              display: false, update: true, latest, current,
            });
            commit('setSnackbar', {
              show: true,
              time: 3000,
              message: `New App Version ${response.message} Available.`,
              color: 'green',
            });
          } else {
            dispatch('setUpdateApp', {
              display: false, update: false, latest, current,
            });
          }
          dispatch('setNavigation', { loading: false });
          commit('setLatestVersion', response.message);
        }
      });
    }
  },
  async saveDefaultsToCenters({ getters }, defaults) {
    const response = await api.saveDefaults(
      defaults,
      getters.server,
    );
    if (response.message === 'unauthenticated') {
      this.dispatch('logout');
    }
    if (response.error) {
      this.commit('setSnackbar', {
        show: true,
        time: 3000,
        message: 'Bad Network Connection, Defaults Not Saved',
        color: 'alert',
      });
    } else if (response && response.status === 'success') {
      this.commit('setDefaults', defaults);
      this.commit('setSnackbar', {
        show: true,
        time: 3000,
        message: 'Changed Defaults',
        color: 'success',
      });
      this.commit('setDefaultsDialog', false);
    }
  },
};
