// eslint-disable-next-line import/prefer-default-export
export default {
  data() {
    return {
      timeValues: [
        { text: '15 minutes', value: 0.25 },
        { text: '30 minutes', value: 0.5 },
        { text: '45 minutes', value: 0.75 },
        { text: '1 hour', value: 1 },
        { text: '1 hour 15 min', value: 1.25 },
        { text: '1 hour 30 min', value: 1.5 },
        { text: '1 hour 45 min', value: 1.75 },
        { text: '2 hours', value: 2 },
        { text: '2 hours 15 min', value: 2.25 },
        { text: '2 hours 30 min', value: 2.5 },
        { text: '2 hours 45 min', value: 2.75 },
        { text: '3 hours', value: 3 },
        { text: '3 hours 15 min', value: 3.25 },
        { text: '3 hours 30 min', value: 3.5 },
        { text: '3 hours 45 min', value: 3.75 },
        { text: '4 hours', value: 4 },
        { text: '4 hours 15 min', value: 4.25 },
        { text: '4 hours 30 min', value: 4.5 },
        { text: '4 hours 45 min', value: 4.75 },
        { text: '5 hours', value: 5 },
        { text: '5 hours 15 min', value: 5.25 },
        { text: '5 hours 30 min', value: 5.5 },
        { text: '5 hours 45 min', value: 5.75 },
        { text: '6 hours', value: 6 },
        { text: '6 hours 15 min', value: 6.25 },
        { text: '6 hours 30 min', value: 6.5 },
        { text: '6 hours 45 min', value: 6.75 },
        { text: '7 hours', value: 7 },
        { text: '7 hours 15 min', value: 7.25 },
        { text: '7 hours 30 min', value: 7.5 },
        { text: '7 hours 45 min', value: 7.75 },
        { text: '8 hours', value: 8 },
        { text: '8 hours 15 min', value: 8.25 },
        { text: '8 hours 30 min', value: 8.5 },
        { text: '8 hours 45 min', value: 8.75 },
        { text: '9 hours', value: 9 },
        { text: '9 hours 15 min', value: 9.25 },
        { text: '9 hours 30 min', value: 9.5 },
        { text: '9 hours 45 min', value: 9.75 },
        { text: '10 hours', value: 10 },
        { text: '10 hours 15 min', value: 10.25 },
        { text: '10 hours 30 min', value: 10.5 },
        { text: '10 hours 45 min', value: 10.75 },
        { text: '11 hours', value: 11 },
        { text: '11 hours 15 min', value: 11.25 },
        { text: '11 hours 30 min', value: 11.5 },
        { text: '11 hours 45 min', value: 11.75 },
        { text: '12 hours', value: 12 },
        { text: '12 hours 15 min', value: 12.25 },
        { text: '12 hours 30 min', value: 12.5 },
        { text: '12 hours 45 min', value: 12.75 },
        { text: '13 hours', value: 13 },
        { text: '13 hours 15 min', value: 13.25 },
        { text: '13 hours 30 min', value: 13.5 },
        { text: '13 hours 45 min', value: 13.75 },
        { text: '14 hours', value: 14 },
        { text: '14 hours 15 min', value: 14.25 },
        { text: '14 hours 30 min', value: 14.5 },
        { text: '14 hours 45 min', value: 14.75 },
        { text: '15 hours', value: 15 },
        { text: '15 hours 15 min', value: 15.25 },
        { text: '15 hours 30 min', value: 15.5 },
        { text: '15 hours 45 min', value: 15.75 },
        { text: '16 hours', value: 16 },
        { text: '16 hours 15 min', value: 16.25 },
        { text: '16 hours 30 min', value: 16.5 },
        { text: '16 hours 45 min', value: 16.75 },
        { text: '17 hours', value: 17 },
        { text: '17 hours 15 min', value: 17.25 },
        { text: '17 hours 30 min', value: 17.5 },
        { text: '17 hours 45 min', value: 17.75 },
        { text: '18 hours', value: 18 },
        { text: '18 hours 15 min', value: 18.25 },
        { text: '18 hours 30 min', value: 18.5 },
        { text: '18 hours 45 min', value: 18.75 },
        { text: '19 hours', value: 19 },
        { text: '19 hours 15 min', value: 19.25 },
        { text: '19 hours 30 min', value: 19.5 },
        { text: '19 hours 45 min', value: 19.75 },
        { text: '20 hours', value: 20 },
        { text: '20 hours 15 min', value: 20.25 },
        { text: '20 hours 30 min', value: 20.5 },
        { text: '20 hours 45 min', value: 20.75 },
        { text: '21 hours', value: 21 },
        { text: '21 hours 15 min', value: 21.25 },
        { text: '21 hours 30 min', value: 21.5 },
        { text: '21 hours 45 min', value: 21.75 },
        { text: '22 hours', value: 22 },
        { text: '22 hours 15 min', value: 22.25 },
        { text: '22 hours 30 min', value: 22.5 },
        { text: '22 hours 45 min', value: 22.75 },
        { text: '23 hours', value: 23 },
        { text: '23 hours 15 min', value: 23.25 },
        { text: '23 hours 30 min', value: 23.5 },
        { text: '23 hours 45 min', value: 23.75 },
      ],
      newTimeArray: [
        { text: '12:00 AM', value: '00:00:00' },
        { text: '12:15 AM', value: '00:15:00' },
        { text: '12:30 AM', value: '00:30:00' },
        { text: '12:45 AM', value: '00:45:00' },
        { text: '1:00 AM', value: '01:00:00' },
        { text: '1:15 AM', value: '01:15:00' },
        { text: '1:30 AM', value: '01:30:00' },
        { text: '1:45 AM', value: '01:45:00' },
        { text: '2:00 AM', value: '02:00:00' },
        { text: '2:15 AM', value: '02:15:00' },
        { text: '2:30 AM', value: '02:30:00' },
        { text: '2:45 AM', value: '02:45:00' },
        { text: '3:00 AM', value: '03:00:00' },
        { text: '3:15 AM', value: '03:15:00' },
        { text: '3:30 AM', value: '03:30:00' },
        { text: '3:45 AM', value: '03:45:00' },
        { text: '4:00 AM', value: '04:00:00' },
        { text: '4:15 AM', value: '04:15:00' },
        { text: '4:30 AM', value: '04:30:00' },
        { text: '4:45 AM', value: '04:45:00' },
        { text: '5:00 AM', value: '05:00:00' },
        { text: '5:15 AM', value: '05:15:00' },
        { text: '5:30 AM', value: '05:30:00' },
        { text: '5:45 AM', value: '05:45:00' },
        { text: '6:00 AM', value: '06:00:00' },
        { text: '6:15 AM', value: '06:15:00' },
        { text: '6:30 AM', value: '06:30:00' },
        { text: '6:45 AM', value: '06:45:00' },
        { text: '7:00 AM', value: '07:00:00' },
        { text: '7:15 AM', value: '07:15:00' },
        { text: '7:30 AM', value: '07:30:00' },
        { text: '7:45 AM', value: '07:45:00' },
        { text: '8:00 AM', value: '08:00:00' },
        { text: '8:15 AM', value: '08:15:00' },
        { text: '8:30 AM', value: '08:30:00' },
        { text: '8:45 AM', value: '08:45:00' },
        { text: '9:00 AM', value: '09:00:00' },
        { text: '9:15 AM', value: '09:15:00' },
        { text: '9:30 AM', value: '09:30:00' },
        { text: '9:45 AM', value: '09:45:00' },
        { text: '10:00 AM', value: '10:00:00' },
        { text: '10:15 AM', value: '10:15:00' },
        { text: '10:30 AM', value: '10:30:00' },
        { text: '10:45 AM', value: '10:45:00' },
        { text: '11:00 AM', value: '11:00:00' },
        { text: '11:15 AM', value: '11:15:00' },
        { text: '11:30 AM', value: '11:30:00' },
        { text: '11:45 AM', value: '11:45:00' },
        { text: '12:00 PM', value: '12:00:00' },
        { text: '12:15 PM', value: '12:15:00' },
        { text: '12:30 PM', value: '12:30:00' },
        { text: '12:45 PM', value: '12:45:00' },
        { text: '1:00 PM', value: '13:00:00' },
        { text: '1:15 PM', value: '13:15:00' },
        { text: '1:30 PM', value: '13:30:00' },
        { text: '1:45 PM', value: '13:45:00' },
        { text: '2:00 PM', value: '14:00:00' },
        { text: '2:15 PM', value: '14:15:00' },
        { text: '2:30 PM', value: '14:30:00' },
        { text: '2:45 PM', value: '14:45:00' },
        { text: '3:00 PM', value: '15:00:00' },
        { text: '3:15 PM', value: '15:15:00' },
        { text: '3:30 PM', value: '15:30:00' },
        { text: '3:45 PM', value: '15:45:00' },
        { text: '4:00 PM', value: '16:00:00' },
        { text: '4:15 PM', value: '16:15:00' },
        { text: '4:30 PM', value: '16:30:00' },
        { text: '4:45 PM', value: '16:45:00' },
        { text: '5:00 PM', value: '17:00:00' },
        { text: '5:15 PM', value: '17:15:00' },
        { text: '5:30 PM', value: '17:30:00' },
        { text: '5:45 PM', value: '17:45:00' },
        { text: '6:00 PM', value: '18:00:00' },
        { text: '6:15 PM', value: '18:15:00' },
        { text: '6:30 PM', value: '18:30:00' },
        { text: '6:45 PM', value: '18:45:00' },
        { text: '7:00 PM', value: '19:00:00' },
        { text: '7:15 PM', value: '19:15:00' },
        { text: '7:30 PM', value: '19:30:00' },
        { text: '7:45 PM', value: '19:45:00' },
        { text: '8:00 PM', value: '20:00:00' },
        { text: '8:15 PM', value: '20:15:00' },
        { text: '8:30 PM', value: '20:30:00' },
        { text: '8:45 PM', value: '20:45:00' },
        { text: '9:00 PM', value: '21:00:00' },
        { text: '9:15 PM', value: '21:15:00' },
        { text: '9:30 PM', value: '21:30:00' },
        { text: '9:45 PM', value: '21:45:00' },
        { text: '10:00 PM', value: '22:00:00' },
        { text: '10:15 PM', value: '22:15:00' },
        { text: '10:30 PM', value: '22:30:00' },
        { text: '10:45 PM', value: '22:45:00' },
        { text: '11:00 PM', value: '23:00:00' },
        { text: '11:15 PM', value: '23:15:00' },
        { text: '11:30 PM', value: '23:30:00' },
        { text: '11:45 PM', value: '23:45:00' },
        { text: '12:00 AM', value: '24:00:00' },
      ],
    };
  },
};
