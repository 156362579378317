/* eslint-disable brace-style */
import api from '../api/index';
// Main Actions for store
export default {

  // Templates are kept in local storage, but we need to
  // check for updates to the color, icon, etc.
  // We also need to add any newly imported templates
  setImportedTemplates({ commit, getters }) {
    return api
      .getAllImportedTemplates(getters.server)
      .then(({ response }) => {
        response.data.forEach((e) => {
          const myApps = JSON.parse(JSON.stringify(getters.myApps));
          const unusedApps = JSON.parse(JSON.stringify(getters.unusedApps));
          const myAppsIndex = myApps.findIndex((app) => {
            return (app.center === e.center
              && app.section === e.section
              && app.tab === e.tab
              && app.kind === e.kind);
          });
          const unusedAppsIndex = unusedApps.findIndex((app) => {
            return (app.center === e.center
              && app.section === e.section
              && app.tab === e.tab
              && app.kind === e.kind);
          });
          const isInMyApps = (myAppsIndex !== -1);
          const isInUnusedApps = (unusedAppsIndex !== -1);

          // If it exists in My Apps: Update the contents
          if (isInMyApps) {
            myApps[myAppsIndex] = e;
            commit('setMyApps', [...myApps]);

            // remove it from Unused Apps if it got there somehow
            if (isInUnusedApps) {
              commit('setUnusedApps', unusedApps.slice(unusedAppsIndex));
            }
          }

          // If it's a new native app template: Add to My Apps
          else if (e.center === 'app'
          && e.section === 'app'
          && e.tab === 'app' && !isInMyApps && !isInUnusedApps) {
            commit('setMyApps', [...getters.myApps, e]);
          }

          // If it exists in Unused Apps: Update the contents
          else if (isInUnusedApps) {
            unusedApps[unusedAppsIndex] = e;
            commit('setUnusedApps', [...unusedApps]);
          }

          // Otherwise: It must be a newly imported template, so add it
          else {
            commit('setUnusedApps', [...getters.unusedApps, e]);
          }
        }); // end forEach

        // Remove apps that shouldn't be shown to the user
        const cleanMyApps = getters.myApps.filter((app) => {
          return response.data.filter((e) => {
            return e.center === app.center
            && e.section === app.section
            && e.tab === app.tab
            && e.kind === app.kind;
          }).length > 0;
        });
        commit('setMyApps', cleanMyApps);

        // Remove apps that shouldn't be shown to the user
        const cleanUnusedApps = getters.unusedApps.filter((app) => {
          return response.data.filter((e) => {
            return e.center === app.center
            && e.section === app.section
            && e.tab === app.tab
            && e.kind === app.kind;
          }).length > 0;
        });
        commit('setUnusedApps', cleanUnusedApps);

        commit('setImportedTemplates', response.data);
      });
  },
  setUnusedApps({ commit, getters }) {
    const unused = getters.importedTemplates.filter((app) => {
      return getters.myApps.filter((e) => {
        return e.center !== app.center
        && e.section !== app.section
        && e.tab !== app.tab
        && e.kind !== app.kind;
      }).length > 0;
    });
    return commit('setUnusedApps', unused);
  },
};
