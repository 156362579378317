<template>
  <v-card id="weeklyCommentForm">
    <v-card-title class="justify-center">
      <h3>Weekly Comment</h3>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-textarea
          id="comment-form-input"
          :value="comment"
          outlined
          label="Comment"
          maxlength="80"
          counter="80"
          @input="handleComment"
        />
      </v-container>
    </v-card-text>
    <v-card-actions
      class="d-flex justify-end"
    >
      <v-btn
        id="cancelSave"
        :loading="saving"
        color="warning"
        @click="$emit('closeComment')"
      >
        Close
      </v-btn>
      <v-btn
        id="saveComment"
        :loading="saving"
        :disabled="!newComment"
        color="success"
        @click="saveComment()"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'WeeklyCommentEntry',
  props: {
    comment: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: () => {
    return {
      saving: false,
      newComment: null,
    };
  },
  methods: {
    handleComment(event) {
      this.newComment = event;
    },
    async saveComment() {
      const userData = {
        date_start: this.$store.getters.dates[0].date,
        date_end: this.$store.getters.dates[6].date,
        comment: this.newComment,
        emp_num: this.$store.getters.user.emp_num,
      };
      this.saving = true;
      const response = await this.$store.dispatch('saveComment', userData);
      this.saving = false;
      if (!response.error) {
        this.$emit('closeComment');
      }
    },
  },
};
</script>
