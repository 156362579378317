export default {
  fuelerEntries(state) {
    return state.fuelerEntries;
  },
  fuelerEntriesOnline(state) {
    return state.fuelerEntriesOnline;
  },
  fuelerLocation(state) {
    return state.fuelerLocation;
  },
  fuelerEquipment(state) {
    return state.fuelerEquipment;
  },
};
