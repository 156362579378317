/* eslint-disable no-param-reassign */
import actions from './actions';
import getters from './getters';

const curVersion = '3.05.34';
localStorage.setItem('cur_version', curVersion);

export default {
  state: {
    //App Version Move to navigation
    latest_version: null,
    current_version: curVersion,
    online: true,
    server: localStorage.getItem('serverURL') || 'https://rinc.us/',
    user: {},
    error: false,
    loading: false,
    dates: {
      last: [],
      present: [],
    },
    costcodes: {},
    equipment: [],
    jobEquipment: {},
    repairCodes: [],
    jobs: [],
    employees: [],
    snackbar: {
      show: false,
      message: '',
      time: 1000,
      color: '',
    },
    defaultsDialog: false,
  },
  mutations: {
    setOnline(state, status) {
      state.online = status;
    },
    setServer(state, server) {
      state.server = server;
    },
    setLatestVersion(state, version) {
      state.latest_version = version;
    },
    setCurrentVersion(state) {
      state.current_version = curVersion;
    },
    setUser(state, user) {
      state.user = user;
    },
    setDefaultsDialog(state, open) {
      state.defaultsDialog = open;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setError(state, status) {
      state.error = status;
    },
    setSnackbar(state, snackbarObject) {
      state.snackbar = snackbarObject;
    },
    setDates(state, dates) {
      state.dates = dates;
    },
    setCostCodes(state, costcodes) {
      state.costcodes = costcodes;
    },
    setEquipment(state, equipment) {
      state.equipment = equipment;
    },
    setJobs(state, jobs) {
      state.jobs = jobs;
    },
    setEmployees(state, employees) {
      state.employees = employees;
    },
    setRepairCodes(state, codes) {
      state.repairCodes = codes;
    },
    setJobEquipment(state, jobEquipment) {
      state.jobEquipment = jobEquipment;
    },
  },
  actions,
  getters,
};
