export default class Timer {
  constructor(callback, delay) {
    this.id = null;
    this.started = null;
    this.remaining = delay;
    this.running = null;
    this.callback = callback;
    this.start();
  }

  start() {
    this.running = true;
    this.started = new Date();
    this.id = setTimeout(this.callback, this.remaining);
  }

  pause() {
    this.running = false;
    clearTimeout(this.id);
    this.remaining -= new Date() - this.started;
  }

  getTimeLeft() {
    if (this.running) {
      this.pause();
      this.start();
    }

    return this.remaining;
  }

  getStateRunning() {
    return this.running;
  }
}
