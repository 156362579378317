import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSnackbar,{attrs:{"id":"dynamic-snackbar-alert","timeout":_vm.$store.getters.snackbar.time || -1,"color":_vm.$store.getters.snackbar.color},model:{value:(_vm.$store.getters.snackbar.show),callback:function ($$v) {_vm.$set(_vm.$store.getters.snackbar, "show", $$v)},expression:"$store.getters.snackbar.show"}},[_c(VFlex,[_c(VLayout,{attrs:{"justify-space-between":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.$store.getters.snackbar.message)+" "),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.$store.getters.snackbar.show = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }