<template>
  <div v-if="$store.getters.online">
    <!-- Week Radio Buttons -->
    <v-alert
      v-if="!truck"
      dense
      tile
      class="rounded-b"
      type="error"
    >
      Unable to find your assigned vehicle.
    </v-alert>
    <v-radio-group
      v-model="week"
      :mandatory="true"
      row
      hide-details
      class="my-0 py-0"
      style="width: 280px; margin: 0 auto; min-width: 280px;"
    >
      <v-radio
        id="lastWeek"
        label="Last Week"
        value="last"
      />
      <v-radio
        id="presentWeek"
        label="This Week"
        value="present"
      />
    </v-radio-group>

    <v-dialog
      v-model="slipTankDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card
        v-if="slipTankDialog"
        class="m-auto"
        tile
        style="width:80%;margin:auto"
      >
        <v-toolbar
          color="blue"
          dense
        >
          <v-toolbar-title> Slip Tank Entry </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            id="saveEntry"
            x-large
            icon
            @click="refresh"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <entryPage
          :truck="truck"
          :logged-in-user="loggedInUser"
          :week="week"
          :locations="locations"
          :equipment="equipment"
          :record-clicked-on="recordClickedOn"
          :existing-record="existingRecord"
          @cancel="refresh"
        />
      </v-card>
    </v-dialog>

    <v-container fluid>
      <v-app-bar
        flat
        color="#e6e6e6"
        dense
      >
        <v-layout
          justify-space-around=""
          align-center
        >
          <v-btn
            color="green"
            @click="slipTankDialog=true"
          >
            Add
          </v-btn>

          <v-divider vertical />
          <span style="font-size: 1.2rem"> Truck: {{ truck || 'N/A' }} </span>
        </v-layout>
      </v-app-bar>
      <v-divider class="mt-4" />

      <v-data-table
        :headers="headers"
        :items="entries"
        fixed-header
        :mobile-breakpoint="0"
        hide-default-footer
        @click:row="handleClick"
      >
      </v-data-table>
    </v-container>
    <Alertbox ref="alertbox" />
  </div>
  <div v-else>
    This Application is not available offline.
  </div>
</template>
<script>
import moment from 'moment/src/moment';
import fetchDataNewAPI from '../../mixins/fetchDataNewAPI.js';
import entryPage from './entryPage';
import Alertbox from '../alert/alert.vue';

export default {
  name: 'SlipTank',
  components: {
    entryPage,
    Alertbox,
  },
  mixins: [fetchDataNewAPI],
  data() {
    return {
      today: moment().format('YYYY-MM-DD'),
      selectedDate: moment().format('YYYY-MM-DD'),
      events: [],
      days: [],
      truck: null,
      server: '',
      loggedInUser: null,
      week: 'present',
      slipTankDialog: false,
      existingRecord: false,
      recordClickedOn: {
        id: null,
        emp_num: null,
        date: null,
        truck: null,
        job_num: null,
        eq_num: null,
        hours: null,
        gallons: null,
      },
      weekdays: [],
      entries: [],
      equipment: [],
      locations: [],
      headers: [
        {
          text: 'Date', value: 'date', align: 'center', width: '34%',
        },
        {
          text: 'EQ Num', value: 'eq_num', align: 'center', width: '33%',
        },
        { text: 'Gallons', value: 'gallons', width: '33%' },
      ],
    };
  },
  computed: {
    isLastOrPresentWeek() {
      const lastArray = this.$store.getters.lastWeekDates.map((d) => {
        return d.date;
      });
      const presentArray = this.$store.getters.presentWeekDates.map((d) => {
        return d.date;
      });

      return (
        this.weekdays[0] === lastArray[0]
        || this.weekdays[0] === presentArray[0]
      );
    },
    dayDate() {
      return `${moment(this.selectedDate).format('MMMM, Do')}`;
    },
    weekRange() {
      return `${moment(this.weekdays[0])
        .format('YYYY/MM/DD')
        .slice(5)} - ${moment(this.weekdays[this.weekdays.length - 1])
        .format('YYYY/MM/DD')
        .slice(5)}`;
    },
  },
  watch: {
    week: {
      handler() {
        this.refreshData();
      },
      deep: true,
    },
  },
  async created() {
    this.server = `${this.$store.getters.server}controller.php`;
    const user = await this.$store.getters.user;
    this.loggedInUser = user.emp_num;
    this.$store.dispatch('getEmployees');

    const truck = await this.$store.dispatch('getMyVehicle');
    const getEquipment = this.$store.getters.equipment;
    const getLocations = await this.getLocations();
    this.truck = truck || null;
    if (!getLocations || !getEquipment) {
      const msg = `Unable to gather jobs/equipment, 
        please navigate to a different part of 
        the app and then try to reopen the sliptank portion.`;
      this.$refs.alertbox.show(msg);
      return false;
    }
    this.locations = getLocations;
    this.equipment = getEquipment;
    this.refreshData();
    return false;
  },

  methods: {
    refresh() {
      this.existingRecord = false;
      this.slipTankDialog = false;
      this.refreshData();
    },
    handleClick(value) {
      this.existingRecord = true;
      this.recordClickedOn = { ...value };
      this.slipTankDialog = true;
    },
    async refreshData() {
      const getWeeklySummary = await this.getWeeklySummary();
      if (!getWeeklySummary) {
        const msg = 'Error retrieving weekly summary data. Please try again.';
        this.$refs.alertbox.show(msg);
        return false;
      }
      this.entries = getWeeklySummary;
      return true;
    },
    async getLoggedInUserTruck() {
      const cstk = ['employee', 'return', 'single', 'assignedEquipmentForEmployee'];
      const userData = {
        emp_num: this.loggedInUser,
      };
      const response = await this.fetchDataNew(cstk, userData, { alternate_url: this.server });
      if (!response.error && !response.empty) {
        return response.response.data;
      }
      return false;
    },
    async getWeeklySummary() {
      const cstk = ['equipment', 'return', 'all', 'sliptankRecordsForWeek'];
      const userData = {
        lastWeekOrPresentWeek: this.week,
        emp_num: this.loggedInUser,
      };
      const response = await this.fetchDataNew(cstk, userData, { alternate_url: this.server });
      if (!response.error && !response.empty) {
        return response.response.data;
      }
      if (response.empty) {
        return [];
      }
      return false;
    },
    async getLocations() {
      const cstk = ['employee', 'return', 'all', 'recentJobsWithLocationsUsedByEmployeeOnTimecardAndTimecardJobs'];
      const userData = {
        emp_num: this.loggedInUser,
      };
      const response = await this.fetchDataNew(cstk, userData, { alternate_url: this.server });
      if (!response.error && !response.empty) {
        for (let i = 0; i < response.response.data.length; i++) {
          const calc = `${response.response.data[i].job_num} - ${response.response.data[i].description}`;
          response.response.data[i].description = calc;
        }
        return response.response.data;
      }
      return false;
    },
  },
};
</script>
