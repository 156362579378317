import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../components/store';

import Login from '../components/login';
import Timecard from '../components/weekly/index';
import Ifta from '../components/ifta/index';
import IftaDay from '../components/ifta/components/iftaDay';
import SlipTank from '../components/sliptank/index';
import AuthenticatedBase from '../components/index';
import fueler from '../components/fueler/index';
import Home from '../components/home/index';
import CentersTemplate from '../components/centersTemplate/index';

Vue.use(VueRouter);
const routes = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/',
    component: AuthenticatedBase,
    children: [
      {
        path: 'weekly',
        name: 'Weekly',
        component: Timecard,
      },
      {
        path: 'ifta',
        name: 'Driver',
        component: Ifta,
      },
      {
        path: 'ifta/:id',
        name: 'Daily Log',
        component: IftaDay,
      },
      {
        path: 'sliptank',
        name: 'Slip Tank',
        component: SlipTank,
      },
      {
        path: 'centers/:center?/:section?/:tab?/:kind?',
        name: 'Centers',
        component: CentersTemplate,
        props: true,
      },
      {
        path: 'fueler',
        name: 'Fueler',
        component: fueler,
      },

      // KEEP THIS LAST
      // Redirects all bad routes to home.
      // This prevents the user from seeing a blank page.
      {
        path: '*',
        name: 'Home',
        component: Home,
      },
    ],
  },
];

// Don't change the mode of the router to history
// This will cause the app to break when the user refreshes the page
const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  await store.restored;
  //Last Location Management
  if (to.path !== '/'
      && to.path !== '/login'
      && !to.path.includes('/ifta/')
  ) {
    localStorage.setItem(`${store.getters.user.username}:lastLocation`, to.path);
  }
  store.dispatch('getDates');
  next();
});
export default router;
