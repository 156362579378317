/* eslint-disable camelcase */
import fetchData from '../../../../utils/fetchData';
import fetchDataNewAPI from '../../../../mixins/fetchDataNewAPI';

export default {
  async fetchDataNew(apicstk, userData, server) {
    return fetchDataNewAPI.methods.fetchDataNew(apicstk, userData, { alternate_url: `${server}controller.php` });
  },
  saveSpecialEntry(entry, server) {
    const cstk = {
      center: 'employee',
      section: 'set',
      tab: 'multiple',
      kind: 'timecardEntries',
    };
    return fetchData(cstk, null, { timecard_entries: [entry] }, server);
  },
  deleteiftaTimecardEntry(id, emp_num, server) {
    const cstk = {
      center: 'employee',
      section: 'delete',
      tab: 'single',
      kind: 'unsubmittedTemporaryTimecardEntry',
    };
    return fetchData(cstk, null, { id, emp_num }, server);
  },
  deleteIftaRecord(id, server) {
    const cstk = {
      center: 'employee',
      section: 'delete',
      tab: 'single',
      kind: 'iftaEntryAndAssociatedTimecardEntries',
    };
    return fetchData(cstk, null, { ifta_id: id }, server);
  },
  getOdometerForVehicle(eq_num, server) {
    const cstk = {
      center: 'equipment',
      section: 'return',
      tab: 'single',
      kind: 'currentAndTotalHourOrMilesForEquipment',
    };
    return fetchData(cstk, null, { eq_num }, server);
  },
  getMyIftaVehicle(emp_num, server) {
    const cstk = {
      center: 'employee',
      section: 'return',
      tab: 'single',
      kind: 'assignedEquipmentForEmployee',
    };
    return fetchData(cstk, null, { emp_num }, server);
  },
  getIftaEquipment(server) {
    const cstk = {
      center: 'equipment',
      section: 'return',
      tab: 'all',
      kind: 'iftaEquipment',
    };
    return fetchData(cstk, null, {}, server);
  },
  getIftaTrailers(server) {
    const cstk = {
      center: 'equipment',
      section: 'return',
      tab: 'all',
      kind: 'trailers',
    };
    return fetchData(cstk, null, {}, server);
  },
  getIftaOverview(userData, server) {
    const ioCstk = ['employee', 'return', 'all', 'iftaOverview'];
    return this.fetchDataNew(ioCstk, userData, server);
  },

  getIftaRecord(userData, server) {
    const ir = ['employee', 'return', 'single', 'iftaRecord'];
    return this.fetchDataNew(ir, userData, server);
  },

  saveIftaRecord(record, server) {
    const cstk = {
      center: 'employee',
      section: 'set',
      tab: 'single',
      kind: 'iftaEntry',
    };
    return fetchData(cstk, null, record, server);
  },

  submitAllRecords(userData, server) {
    const cstk = {
      center: 'employee',
      section: 'action',
      tab: 'all',
      kind: 'submitTemporaryTimecardEntriesForGivenWeek',
    };
    return fetchData(cstk, null, userData, server);
  },

  getLastUsedData(emp_num, server) {
    const userData = {
      emp_num,
    };
    const job_cstk = {
      center: 'employee',
      section: 'return',
      tab: 'all',
      kind: 'last5UsedJobsForGivenEmployee',
    };
    const eq_cstk = {
      center: 'employee',
      section: 'return',
      tab: 'all',
      kind: 'last5UsedEqNumsForGivenEmployee',
    };
    return Promise.all([
      this.fetchDataNew(job_cstk, userData, server),
      this.fetchDataNew(eq_cstk, userData, server),
    ]);
  },
};
